import styled from "styled-components";

//we are setting the width as 75vw in .sidebarTop and itemsContainer 
//not in percentage to avoid shifting of text when the width decreases for transition

export const MobileSidebarStyle = styled.div`
  width: ${props=>props.isShowSidebar?'75vw':'0'};
  font-family: Verdana, sans-serif;
  position: fixed;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: ${props=>props?.theme?.color?.white};
  padding: 10px 0px;
  transition: 0.2s;
  overflow: hidden;
  box-shadow: 10px 10px 15px -6px rgba(0,0,0,0.25);

  .SidebarItem{
    padding: 10px 10px;
    text-align: center;
  }
  .sidebarTop{
    width: 75vw;
  }
  .itemsContainer{
    width: 75vw;
    background-color: ${props=>props?.theme?.color?.textPrimary};
    height: 90vh;
  }
`