import APIContext from './context';
import { useState, useRef, useCallback, useContext } from 'react';
import { loginApi, logoutApi, signupApi, validateApi, getCategoriesApi, getProductsApi, placeOrderApi, validatePaymentApi, addAddressApi, getAddressesApi, getDeliveryDetailApi, getOrdersApi, getOrderedProductDetailApi, getWhitelabelApi, getTotalSaleAmount, updateSupplierVariantApi, getSupplierProductsApi, getSupplierProductDetailApi, updateInventoryApi, updateSupplierProductApi, getLastInventoryJobApi, updateInventoryJobApi, getBrandsApi, upsertNewProductRequestApi, getDataUploadUrlApi, getProductRequestDetailApi, upsertNewVariantRequestApi, getProductReqListApi, deleteAccountRequestApi } from '../../config/urls';
import { parseError, getAuthHeaders, getHeaders, queryBuilder, getUserProfileUtil } from '../../helpers/utility';
import Cookie from 'js-cookie'
import GlobalContext from '../global/context';
import { SUPPLIER_DATA } from '../../constants';

// {varname}_l stands for loaded = true/false keeping this as loaded instead of loading to avoid initial change of undefined(API not called)
// to true and then to false which cases glich if we add condition on loading=true

function APIProvider(props){
  const {handleSetgstate, handleSetTheme} = useContext(GlobalContext)
  //using variable globalStat variable and method below to avoid inconsistency caused by multiple API's response updating global object
  const handleUpdateGlobal = useCallback((newData)=>{
    globalStat.current = {
      ...globalStat.current,
      ...newData
    }
    setGlobalState(globalStat.current)
  },[])

  const getTotalSalesAmount = useCallback(async ()=>{
    handleUpdateGlobal({
      isLoading: true,
      getTotalSalesAmountData_l: false
    })
    let respData = ''
    const response = await fetch(getTotalSaleAmount,{
      headers: getAuthHeaders()
    })
    if(response.ok){
      const json = await response.json()
      respData = {
        status: 'success',
        data: json
      }
    }else{
      const errorJson = await response.json()
      respData = {
        status: 'failed',
        error: parseError(errorJson.error)
      }
    }
    handleUpdateGlobal({
      isLoading: false,
      getTotalSalesAmountData: respData,
      getTotalSalesAmountData_l: true
    })
    return respData
  },[])

  const getOrders = useCallback(async (queryObj={}, dataKey='getOrdersData')=>{
    handleUpdateGlobal({
      isLoading: true,
      [`${dataKey}_l`]: false,
    })
    const queryString = queryBuilder(queryObj)
    let respData = ''
    const response = await fetch(`${getOrdersApi}/${queryString}`,{
      headers: getAuthHeaders(),
      method: 'GET'
    })
    if(response.ok){
      const json = await response.json()
      respData = {
        status: 'success',
        data: json
      }
    }else{
      const errorJson = await response.json()
      respData = {
        status: 'failed',
        error: parseError(errorJson.error)
      }
    }
    handleUpdateGlobal({
      isLoading: false,
      [`${dataKey}_l`]: true,
      [dataKey]: respData
    })
    return respData
  },[])

  const getOrderDetail = useCallback(async (orderProductId)=>{
    handleUpdateGlobal({
      isLoading: true,
      getOrderDetailData_l: false
    })
    let respData = ''
    const response = await fetch(`${getOrderedProductDetailApi(orderProductId)}`,{
      headers: getAuthHeaders(),
      method: 'GET'
    })
    if(response.ok){
      const json = await response.json()
      respData = {
        status: 'success',
        data: json
      }
    }else{
      const errorJson = await response.json()
      respData = {
        status: 'failed',
        error: parseError(errorJson.error)
      }
    }
    handleUpdateGlobal({
      isLoading: false,
      getOrderDetailData: respData,
      getOrderDetailData_l: true
    })
    return respData
  },[])

  const getLastInventoryJob = useCallback(async ()=>{
    handleUpdateGlobal({
      isLoading: true,
      getLastInventoryJobData_l: false
    })
    let respData = ''
    const response = await fetch(getLastInventoryJobApi,{
      headers: getAuthHeaders(),
      method: 'GET'
    })
    if(response.ok){
      const json = await response.json()
      respData = {
        status: 'success',
        data: json
      }
    }else{
      const errorJson = await response.json()
      respData = {
        status: 'failed',
        error: parseError(errorJson.error)
      }
    }
    handleUpdateGlobal({
      isLoading: false,
      getLastInventoryJobData: respData,
      getLastInventoryJobData_l: true
    })
    return respData
  },[])

  const updateInventoryJob = useCallback(async (body)=>{
    handleUpdateGlobal({
      isLoading: true,
      updateInventoryJob_l: false
    })
    let respData = ''
    const response = await fetch(`${updateInventoryJobApi(body.jobId)}`,{
      headers: getAuthHeaders(),
      method: 'POST',
      body: JSON.stringify(body)
    })
    if(response.ok){
      const json = await response.json()
      respData = {
        status: 'success',
        data: json
      }
    }else{
      const errorJson = await response.json()
      respData = {
        status: 'failed',
        error: parseError(errorJson.error)
      }
    }
    handleUpdateGlobal({
      isLoading: false,
      updateInventoryJob: respData,
      updateInventoryJob_l: true
    })
    return respData
  },[])

  const updateSupplierVariant = useCallback(async (body)=>{
    handleUpdateGlobal({
      isLoading: true,
      updateSupplierVariantData_l: false
    })
    let respData = ''
    const response = await fetch(`${updateSupplierVariantApi(body.supplierVariantId)}`,{
      headers: getAuthHeaders(),
      method: 'POST',
      body: JSON.stringify(body)
    })
    if(response.ok){
      const json = await response.json()
      respData = {
        status: 'success',
        data: json
      }
    }else{
      const errorJson = await response.json()
      respData = {
        status: 'failed',
        error: parseError(errorJson.error)
      }
    }
    handleUpdateGlobal({
      isLoading: false,
      updateSupplierVariantData: respData,
      updateSupplierVariantData_l: true
    })
    return respData
  },[])

  const updateSupplierProduct = useCallback(async (body)=>{
    handleUpdateGlobal({
      isLoading: true,
      updateSupplierProductData_l: false
    })
    let respData = ''
    const response = await fetch(`${updateSupplierProductApi(body.supplierProductId)}`,{
      headers: getAuthHeaders(),
      method: 'POST',
      body: JSON.stringify(body)
    })
    if(response.ok){
      const json = await response.json()
      respData = {
        status: 'success',
        data: json
      }
    }else{
      const errorJson = await response.json()
      respData = {
        status: 'failed',
        error: parseError(errorJson.error)
      }
    }
    handleUpdateGlobal({
      isLoading: false,
      updateSupplierProductData: respData,
      updateSupplierProductData_l: true
    })
    return respData
  },[])

  const updateInventory = useCallback(async (body)=>{
    handleUpdateGlobal({
      isLoading: true,
      updateInventoryData_l: false
    })
    let respData = ''
    const response = await fetch(updateInventoryApi,{
      headers: getAuthHeaders(),
      method: 'POST',
      body: JSON.stringify(body)
    })
    if(response.ok){
      const json = await response.json()
      respData = {
        status: 'success',
        data: json
      }
    }else{
      const errorJson = await response.json()
      respData = {
        status: 'failed',
        error: parseError(errorJson.error)
      }
    }
    handleUpdateGlobal({
      isLoading: false,
      updateInventoryData: respData,
      updateInventoryData_l: true
    })
    return respData
  },[])

  const getSupplierProductDetail = useCallback(async (productId)=>{
    handleUpdateGlobal({
      isLoading: true,
      getSupplierProductDetailData_l: false
    })
    let respData = ''
    const response = await fetch(`${getSupplierProductDetailApi(productId)}`,{
      headers: getAuthHeaders(),
      method: 'GET'
    })
    if(response.ok){
      const json = await response.json()
      respData = {
        status: 'success',
        data: json
      }
    }else{
      const errorJson = await response.json()
      respData = {
        status: 'failed',
        error: parseError(errorJson.error)
      }
    }
    handleUpdateGlobal({
      isLoading: false,
      getSupplierProductDetailData_l: true,
      getSupplierProductDetailData: respData
    })
    return respData
  },[])

  const getSupplierProducts = useCallback(async (queryObj={}, dataKey='getSupplierProductsData')=>{
    //this returns supplier products, used to see supplier's products 
    handleUpdateGlobal({
      isLoading: true,
      [`${dataKey}_l`]: false
    })
    const queryString = queryBuilder(queryObj)
    let respData = ''
    const response = await fetch(`${getSupplierProductsApi}/${queryString}`,{
      headers: getAuthHeaders(),
      method: 'GET'
    })
    if(response.ok){
      const json = await response.json()
      respData = {
        status: 'success',
        data: json
      }
    }else{
      const errorJson = await response.json()
      respData = {
        status: 'failed',
        error: parseError(errorJson.error)
      }
    }
    handleUpdateGlobal({
      isLoading: false,
      [`${dataKey}_l`]: true,
      [dataKey]: respData
    })
    return respData
  },[])

  const getProducts = useCallback(async (queryObj={}, dataKey='getProductsData')=>{
    //this returns all products, used to addProduct
    handleUpdateGlobal({
      isLoading: true,
      [`${dataKey}_l`]: false
    })
    const queryString = queryBuilder(queryObj)
    let respData = ''
    const response = await fetch(`${getProductsApi}/${queryString}`,{
      headers: getAuthHeaders(),
      method: 'GET'
    })
    if(response.ok){
      const json = await response.json()
      respData = {
        status: 'success',
        data: json
      }
    }else{
      const errorJson = await response.json()
      respData = {
        status: 'failed',
        error: parseError(errorJson.error)
      }
    }
    handleUpdateGlobal({
      isLoading: false,
      [`${dataKey}_l`]: true,
      [dataKey]: respData
    })
    return respData
  },[])

  const getCategories = useCallback(async (queryObj={})=>{
    handleUpdateGlobal({
      isLoading: true,
      getCategoriesData_l: false
    })
    const queryString = queryBuilder(queryObj)
    let respData = ''
    const response = await fetch(`${getCategoriesApi}/${queryString}`,{
      headers: getHeaders(),
      method: 'GET'
    })
    if(response.ok){
      const json = await response.json()
      respData = {
        status: 'success',
        data: json
      }
    }else{
      const errorJson = await response.json()
      respData = {
        status: 'failed',
        error: parseError(errorJson.error)
      }
    }
    handleUpdateGlobal({
      isLoading: false,
      getCategoriesData_l: true,
      getCategoriesData: respData
    })
    return respData
  },[])

  const getBrands = useCallback(async (queryObj={})=>{
    handleUpdateGlobal({
      isLoading: true,
      getBrandsData_l: false
    })
    const queryString = queryBuilder(queryObj)
    let respData = ''
    const response = await fetch(`${getBrandsApi}/${queryString}`,{
      headers: getHeaders(),
      method: 'GET'
    })
    if(response.ok){
      const json = await response.json()
      respData = {
        status: 'success',
        data: json
      }
    }else{
      const errorJson = await response.json()
      respData = {
        status: 'failed',
        error: parseError(errorJson.error)
      }
    }
    handleUpdateGlobal({
      isLoading: false,
      getBrandsData_l: true,
      getBrandsData: respData
    })
    return respData
  },[])

  const logout = useCallback(async ()=>{
    handleUpdateGlobal({
      isLoading: true,
      logoutData_l: false
    })
    let respData = ''
    const response = await fetch(logoutApi,{
      headers: getAuthHeaders(),
      method: 'POST'
    })
    if(response.ok){
      const json = await response.json()
      Cookie.remove('authToken')
      Cookie.remove('userProfile')
      respData = {
        status: 'success',
        data: json
      }
    }else{
      const errorJson = await response.json()
      respData = {
        status: 'failed',
        error: parseError(errorJson.error)
      }
    }
    handleUpdateGlobal({
      isLoading: false,
      logoutData: respData,
      logoutData_l: true
    })
    return respData
  },[])

  const login = useCallback(async (body)=>{
    handleUpdateGlobal({
      isLoading: true,
      loginData_l: false
    })
    let respData = ''
    const response = await fetch(loginApi,{
      headers: getHeaders(),
      method: 'POST',
      body: JSON.stringify(body)
    })
    if(response.ok){
      const json = await response.json()
      respData = {
        status: 'success',
        data: json
      }
      Cookie.set('authToken', json.token)
      // getUserProfileUtil(true)
    }else{
      const errorJson = await response.json()
      respData = {
        status: 'failed',
        error: parseError(errorJson.error),
        rawError: errorJson.error
      }
    }
    handleUpdateGlobal({
      isLoading: false,
      loginData: respData,
      loginData_l: true
    })
    return respData
  },[])

  const deleteAccountRequest = useCallback(async (body)=>{
    handleUpdateGlobal({
      isLoading: true,
      deleteAccountRequestData_l: false
    })
    let respData = ''
    const response = await fetch(deleteAccountRequestApi,{
      headers: getHeaders(),
      method: 'POST',
      body: JSON.stringify(body)
    })
    if(response.ok){
      const json = await response.json()
      respData = {
        status: 'success',
        data: json
      }
    }else{
      const errorJson = await response.json()
      respData = {
        status: 'failed',
        error: parseError(errorJson.error),
        rawError: errorJson.error
      }
    }
    handleUpdateGlobal({
      isLoading: false,
      deleteAccountRequestData: respData,
      deleteAccountRequestData_l: true
    })
    return respData
  },[])

  const getDataUploadUrl = useCallback(async (queryObj={})=>{
    handleUpdateGlobal({
      isLoading: true,
      getDataUploadUrlData_l: false
    })
    const queryString = queryBuilder(queryObj)
    let respData = ''
    const response = await fetch(`${getDataUploadUrlApi}/${queryString}`,{
      headers: getHeaders(),
      method: 'GET'
    })
    if(response.ok){
      const json = await response.json()
      respData = {
        status: 'success',
        data: json
      }
    }else{
      const errorJson = await response.json()
      respData = {
        status: 'failed',
        error: parseError(errorJson.error)
      }
    }
    handleUpdateGlobal({
      isLoading: false,
      getDataUploadUrlData_l: true,
      getDataUploadUrlData: respData
    })
    return respData
  },[])

  const getProductRequestDetail = useCallback(async (productReqId={})=>{
    handleUpdateGlobal({
      isLoading: true,
      getProductRequestDetailData_l: false
    })
    let respData = ''
    const response = await fetch(getProductRequestDetailApi(productReqId),{
      headers: getAuthHeaders(),
      method: 'GET'
    })
    if(response.ok){
      const json = await response.json()
      respData = {
        status: 'success',
        data: json
      }
    }else{
      const errorJson = await response.json()
      respData = {
        status: 'failed',
        error: parseError(errorJson.error)
      }
    }
    handleUpdateGlobal({
      isLoading: false,
      getProductRequestDetailData_l: true,
      getProductRequestDetailData: respData
    })
    return respData
  },[])
  
  const upsertNewProductRequest = useCallback(async (body)=>{
    handleUpdateGlobal({
      isLoading: true,
      upsertNewProductRequestData_l: false
    })
    let respData = ''
    const response = await fetch(upsertNewProductRequestApi,{
      headers: getAuthHeaders(),
      method: 'POST',
      body: JSON.stringify(body)
    })
    if(response.ok){
      const json = await response.json()
      respData = {
        status: 'success',
        data: json
      }
    }else{
      const errorJson = await response.json()
      respData = {
        status: 'failed',
        error: parseError(errorJson.error)
      }
    }
    handleUpdateGlobal({
      isLoading: false,
      upsertNewProductRequestData: respData,
      upsertNewProductRequestData_l: true
    })
    return respData
  },[])

  const upsertNewVariantRequest = useCallback(async (body)=>{
    handleUpdateGlobal({
      isLoading: true,
      upsertNewVariantRequestData_l: false
    })
    let respData = ''
    const response = await fetch(upsertNewVariantRequestApi,{
      headers: getAuthHeaders(),
      method: 'POST',
      body: JSON.stringify(body)
    })
    if(response.ok){
      const json = await response.json()
      respData = {
        status: 'success',
        data: json
      }
    }else{
      const errorJson = await response.json()
      respData = {
        status: 'failed',
        error: parseError(errorJson.error)
      }
    }
    handleUpdateGlobal({
      isLoading: false,
      upsertNewVariantRequestData: respData,
      upsertNewVariantRequestData_l: true
    })
    return respData
  },[])


  const getProductReqList = useCallback(async (queryObj={})=>{
    handleUpdateGlobal({
      isLoading: true,
      getProductReqListData_l: false
    })
    const queryString = queryBuilder(queryObj)
    let respData = ''
    const response = await fetch(`${getProductReqListApi}/${queryString}`,{
      headers: getAuthHeaders(),
      method: 'GET'
    })
    if(response.ok){
      const json = await response.json()
      respData = {
        status: 'success',
        data: json
      }
    }else{
      const errorJson = await response.json()
      respData = {
        status: 'failed',
        error: parseError(errorJson.error)
      }
    }
    handleUpdateGlobal({
      isLoading: false,
      getProductReqListData_l: true,
      getProductReqListData: respData
    })
    return respData
  },[])


  const signup = useCallback(async (body)=>{
    handleUpdateGlobal({
      isLoading: true,
      signupData_l: false
    })
    let respData = ''
    const response = await fetch(signupApi,{
      headers: getHeaders(),
      method: 'POST',
      body: JSON.stringify(body)
    })
    if(response.ok){
      const json = await response.json()
      respData = {
        status: 'success',
        data: json
      }
    }else{
      const errorJson = await response.json()
      respData = {
        status: 'failed',
        error: parseError(errorJson.error)
      }
    }
    handleUpdateGlobal({
      isLoading: false,
      signupData: respData,
      signupData_l: true
    })
    return respData
  },[])

  const validateOtp = useCallback(async (body)=>{
    handleUpdateGlobal({
      isLoading: true,
      validateOtpData_l: false
    })
    let respData = ''
    const response = await fetch(validateApi,{
      headers: getHeaders(),
      method: 'POST',
      body: JSON.stringify(body)
    })
    if(response.ok){
      const json = await response.json()
      respData = {
        status: 'success',
        data: json
      }
    }else{
      const errorJson = await response.json()
      respData = {
        status: 'failed',
        error: parseError(errorJson.error)
      }
    }
    handleUpdateGlobal({
      isLoading: false,
      validateOtpData: respData,
      validateOtpData_l: true
    })
    return respData
  },[])

  const resetState = useCallback((key)=>{
    globalStat.current[key]['status']=''
    setGlobalState(globalStat.current)
  },[])

  //this should be called whenever a particular page is unmounted, so when it's visited again the loader by default is undefined 
  //and the slight flickering doesn't happen
  const resetLoaders = useCallback((arr)=>{
    arr.forEach((key)=>{
      globalStat.current[`${key}_l`]=undefined
    })
  },[])

  const getWhitelabel = useCallback(async ()=>{
    handleUpdateGlobal({
      isLoading: true
    })
    let respData = ''
    const response = await fetch(getWhitelabelApi)
    if(response.ok){
      const json = await response.json()
      respData = {
        status: 'success',
        data: json
      }
      handleSetgstate(SUPPLIER_DATA,json?.data)
      // handleSetTheme(JSON.parse(json?.data?.themeData || {}))
    }else{
      const errorJson = await response.json()
      respData = {
        status: 'failed',
        error: parseError(errorJson.error)
      }
    }
    handleUpdateGlobal({
      isLoading: false,
      getWhitelabelData: respData
    })
    return respData
  },[])

  const placeOrder = useCallback(async (body)=>{
    handleUpdateGlobal({
      isLoading: true
    })
    let respData = ''
    const response = await fetch(placeOrderApi,{
      headers: getAuthHeaders(),
      method: 'POST',
      body: JSON.stringify(body)
    })
    if(response.ok){
      const json = await response.json()
      respData = {
        status: 'success',
        data: json
      }
    }else{
      const errorJson = await response.json()
      respData = {
        status: 'failed',
        error: parseError(errorJson.error)
      }
    }
    handleUpdateGlobal({
      isLoading: false,
      placeOrderData: respData
    })
    return respData
  },[])

  const addAddress = useCallback(async (body)=>{
    handleUpdateGlobal({
      isLoading: true
    })
    let respData = ''
    const response = await fetch(addAddressApi,{
      headers: getAuthHeaders(),
      method: 'POST',
      body: JSON.stringify(body)
    })
    if(response.ok){
      const json = await response.json()
      respData = {
        status: 'success',
        data: json
      }
    }else{
      const errorJson = await response.json()
      respData = {
        status: 'failed',
        error: parseError(errorJson.error)
      }
    }
    handleUpdateGlobal({
      isLoading: false,
      addAddressData: respData
    })
    return respData
  },[])

  const getAddresses = useCallback(async (body)=>{
    handleUpdateGlobal({
      isLoading: true
    })
    let respData = ''
    const response = await fetch(getAddressesApi,{
      headers: getAuthHeaders(),
      method: 'GET'
    })
    if(response.ok){
      const json = await response.json()
      respData = {
        status: 'success',
        data: json
      }
    }else{
      const errorJson = await response.json()
      respData = {
        status: 'failed',
        error: parseError(errorJson.error)
      }
    }
    handleUpdateGlobal({
      isLoading: false,
      getAddressesData: respData
    })
    return respData
  },[])

  const getDeliveryDetail = useCallback(async (queryObj,body)=>{
    handleUpdateGlobal({
      isLoading: true
    })
    let respData = ''
    const queryString = queryBuilder(queryObj)
    const response = await fetch(`${getDeliveryDetailApi}/${queryString}`,{
      headers: getHeaders(),
      method: 'POST',
      body: JSON.stringify(body)
    })
    if(response.ok){
      const json = await response.json()
      respData = {
        status: 'success',
        data: json
      }
    }else{
      const errorJson = await response.json()
      respData = {
        status: 'failed',
        error: parseError(errorJson.error)
      }
    }
    handleUpdateGlobal({
      isLoading: false,
      getDeliveryDetailData: respData
    })
    return respData
  },[])


  const validatePayment = useCallback(async (body)=>{
    handleUpdateGlobal({
      isLoading: true,
      isPaymentProcessing: true
    })
    let respData = ''
    const response = await fetch(validatePaymentApi,{
      headers: getAuthHeaders(),
      method: 'POST',
      body: JSON.stringify(body)
    })
    if(response.ok){
      const json = await response.json()
      respData = {
        status: 'success',
        data: json
      }
    }else{
      const errorJson = await response.json()
      respData = {
        status: 'failed',
        error: parseError(errorJson.error)
      }
    }
    handleUpdateGlobal({
      isLoading: false,
      isPaymentProcessing: false,
      validatePaymentData: respData
    })
    return respData
  },[])

  const globalStat = useRef({
    isLoading: true,
    getTotalSalesAmount,
    getProducts,
    getCategories,
    getBrands,
    getSupplierProducts,
    getSupplierProductDetail,
    getOrders,
    getOrderDetail,
    updateSupplierProduct,
    updateSupplierVariant,
    logout,
    login,
    deleteAccountRequest,
    updateInventory,
    getLastInventoryJob,
    getProductRequestDetail,
    updateInventoryJob,
    upsertNewProductRequest,
    upsertNewVariantRequest,
    getDataUploadUrl,
    getProductReqList,
    signup,
    resetState,
    resetLoaders,

    validateOtp,
    getWhitelabel,
    placeOrder,
    addAddress,
    getAddresses,
    getDeliveryDetail,
    validatePayment,
  })

  const [globalState, setGlobalState] = useState({...globalStat.current})

  return (
    <APIContext.Provider
      value={globalState}
    >
      {props.children}
    </APIContext.Provider>
  )
}

export default APIProvider