import styled from "styled-components"

export const ProductsStyle = styled.div`
  padding: 20px 10px 0px;
  margin-bottom: 10vh;
  .filters{
    margin-top: 10px;
  }
  .items-list{
    padding-top: 10px;
  }
  .add-icon{
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
`