import { useContext, useRef, useState } from 'react'
import APIContext from '../../../../contexts/apis/context'
import { useEffect } from 'react'
import ShowSnackbar from '../../../../components/ShowSnackbar'
import { useCallback } from 'react'
import Layout from '../../../../components/layouts/Layout'
import Searchbar from '../../../../components/SearchBar'
import { AddProductFormStyle } from './index.style'
import VariantList from '../VariantsList'
import { isEmpty } from 'lodash'
import InventoryConfirmationPopup from '../InventoryConfirmationPopup'
import { useNavigate } from 'react-router-dom'

function AddProductForm(props) {
  const { getProducts, getProductsData, getProductsData_l,
    getSupplierProductDetail, getSupplierProductDetailData, getProductsDetailData_l,
    updateInventory, updateInventoryData, updateInventoryData_l, resetState
  } = useContext(APIContext)

  const { handleResetInventoryJob } = props
  const navigate = useNavigate()

  const [products, setProducts] = useState([])
  const [variants, setVariants] = useState([])
  const [showConfirmationDialogue, setShowConfirmationDialogue] = useState(false)
  const [finalInventory, setFinalInventory] = useState()
  const [selectedProduct, setSelectedProduct] = useState({})

  //only getting top 10 products from the search
  const pageSize = useRef(10)
  const pageNumber = useRef(0)

  useEffect(() => {
    if (getProductsData && getProductsData.status === 'success') {
      setProducts(getProductsData?.data?.data)
      resetState('getProductsData')
    }
    else if (getProductsData && getProductsData.status === 'failed') {
      ShowSnackbar.error({
        text: getProductsData.error,
        visible: true
      })
      resetState('getProductsData')
    }
    if (getSupplierProductDetailData && getSupplierProductDetailData.status === 'success') {
      setSelectedProduct(getSupplierProductDetailData?.data?.data)
      setVariants(getSupplierProductDetailData.data.data.variant)
      resetState('getSupplierProductDetailData')
    }
    else if (getSupplierProductDetailData && getSupplierProductDetailData.status === 'failed') {
      ShowSnackbar.error({
        text: getSupplierProductDetailData.error,
        visible: true
      })
      resetState('getSupplierProductDetailData')
    }
    if (updateInventoryData && updateInventoryData.status === 'success') {
      handleResetInventoryJob()
      ShowSnackbar.success({
        text: 'Success',
        visible: true
      })
      resetState('updateInventoryData')
    }
    else if (updateInventoryData && updateInventoryData.status === 'failed') {
      ShowSnackbar.error({
        text: updateInventoryData.error,
        visible: true
      })
      resetState('updateInventoryData')
    }
  }, [getProductsData, getSupplierProductDetailData, updateInventoryData, handleResetInventoryJob])

  const handleInputChange = useCallback((val) => {
    if (val) {
      getProducts({
        productName: val,
        pageSize: pageSize.current,
        pageNumber: pageNumber.current
      })
    }
  }, [getProducts])

  const handleToggleConfirmationDialogue = useCallback(() => {
    setShowConfirmationDialogue(d => !d)
  }, [])

  const handleComplete = useCallback((fInventory) => {
    const productVariantMap = {}
    for (const variantId in fInventory) {
      if (fInventory.hasOwnProperty(variantId)) {
        const vData = fInventory[variantId]
        if (vData.productId in productVariantMap) {
          productVariantMap[vData.productId].variant.push({
            ...vData
          })
        } else {
          productVariantMap[vData.productId] = {
            productId: vData.productId,
            productName: vData.productName,
            variant: [{
              ...vData
            }]
          }
        }
      }
    }
    setFinalInventory(Object.values(productVariantMap))
    handleToggleConfirmationDialogue()
  }, [handleToggleConfirmationDialogue])

  const handleChange = useCallback((selectedProduct) => {
    if (!isEmpty(selectedProduct) && selectedProduct?.productId) {
      getSupplierProductDetail(selectedProduct.productId)
    }else if(selectedProduct?.productName){
      navigate('/add-new-product')
    }
  }, [getSupplierProductDetail])

  const handleUpdateInventory = useCallback(() => {
    updateInventory(finalInventory)
  }, [finalInventory])

  return (
    <Layout title="Add Product">
      <AddProductFormStyle>
        <Searchbar
          loading={getProductsData_l === undefined ? false : !getProductsData_l} //loader is undefined by default
          onInputChange={handleInputChange}
          onChange={handleChange}
          options={products}
          optionLabelKey="productName"
        />
        <VariantList product={selectedProduct} variants={variants} onComplete={handleComplete} />
        <InventoryConfirmationPopup
          showConfirmationDialogue={showConfirmationDialogue}
          handleToggleConfirmationDialogue={handleToggleConfirmationDialogue}
          handleUpdateInventory={handleUpdateInventory}
          finalInventory={finalInventory}
        />
      </AddProductFormStyle>
    </Layout>
  )
}

export default AddProductForm