import { AddNewProductStyle } from './index.style'
import Layout from '../../components/layouts/Layout'
import Form from '../../components/Form'
import { Alert, Button } from '@mui/material'
import { useCallback, useRef } from 'react'
import APIContext from '../../contexts/apis/context'
import { useEffect } from 'react'
import ShowSnackbar from '../../components/ShowSnackbar'
import { useState } from 'react'
import { useContext } from 'react'
import { uploadImagesToAwsSigned } from '../../helpers/utility'
import CircularProgressWithLabel from '../../components/CircularProgressWithLabel'
import { Row } from '../../commonStyles/index.style'
import { useNavigate } from 'react-router-dom'

function AddNewProduct() {
  const submitFormRef = useRef('')
  const navigate = useNavigate()

  const [imageUploadPercent, setImageUploadPercent] = useState(0)

  const { getCategories, getCategoriesData, getCategoriesData_l,
    getBrands, getBrandsData, getBrandsData_l,
    upsertNewProductRequest, upsertNewProductRequestData, upsertNewProductRequestData_l,
    resetState, resetLoaders
  } = useContext(APIContext)

  const [categories, setCategories] = useState([])
  const [brands, setBrands] = useState([])

  const handleSubmit = useCallback(async (e) => {
    const response = submitFormRef.current(e)
    if (response) {
      //upload images and then call upsert-product-req api and get a productReqId and pass it to add new variant screen
      uploadImagesToAwsSigned(response.productImages,(percent, uploadedFilePaths)=>{
        setImageUploadPercent(percent)
        if(percent === 100){
          upsertNewProductRequest({
            ...response,
            productImages: uploadedFilePaths
          })
        }
      },(err)=>{
        ShowSnackbar.error({
          text: "Some error occured while uploading images",
          visible: true
        })
      })
    }
  }, [])
  
  useEffect(()=>{
    getCategories()
    getBrands()
    return ()=>{
      //resetting all loaders as when the page is visited again, the loader by default is false
      resetLoaders(['getCategoriesData','getBrandsData','upsertNewProductRequestData'])
    }
  },[])

  useEffect(()=>{
    if (getCategoriesData && getCategoriesData.status === 'success') {
      setCategories(getCategoriesData?.data?.data)
      resetState('getCategoriesData')
    }
    else if (getCategoriesData && getCategoriesData.status === 'failed') {
      ShowSnackbar.error({
        text: getCategoriesData.error,
        visible: true
      })
      resetState('getCategoriesData')
    }
    if (getBrandsData && getBrandsData.status === 'success') {
      setBrands(getBrandsData?.data?.data)
      resetState('getBrandsData')
    }
    else if (getBrandsData && getBrandsData.status === 'failed') {
      ShowSnackbar.error({
        text: getBrandsData.error,
        visible: true
      })
      resetState('getBrandsData')
    }
    if (upsertNewProductRequestData && upsertNewProductRequestData.status === 'success') {
      const productReqId = upsertNewProductRequestData?.data?.data?.productReqId
      resetState('upsertNewProductRequestData')
      navigate(`/add-new-variant/${productReqId}`)
    }
    else if (upsertNewProductRequestData && upsertNewProductRequestData.status === 'failed') {
      ShowSnackbar.error({
        text: upsertNewProductRequestData.error,
        visible: true
      })
      resetState('upsertNewProductRequestData')
    }
  },[getCategoriesData, getBrandsData, upsertNewProductRequestData])

  const getSubmitMethod = useCallback((submitMethod) => {
    submitFormRef.current = submitMethod
  }, [])

  const formData = getAddNewProductForm({categories, brands})

  return (
    <Layout title="Add New Product">
      <AddNewProductStyle>
      {
        imageUploadPercent > 0 ?
        <>
          <Alert sx={{ marginBottom: '20px' }} severity="warning">
            Don't change the page while we add the product for you
          </Alert>
          <Row justify="center">
            <CircularProgressWithLabel value={imageUploadPercent} />
          </Row>
        </>
        :
        <>
          <Form
            formData={formData}
            getSubmitMethod={getSubmitMethod}
          />
          <Button fullWidth sx={{ marginBottom: '20px', marginTop: "30px" }}
            size="large"
            variant="contained"
            onClick={handleSubmit}
          >
            Add Product
          </Button>
        </>
      }
      </AddNewProductStyle>
    </Layout>
  )
}

export default AddNewProduct

const getAddNewProductForm = ({data,categories,brands}) => {
  let variantForm = [
    {
      label: 'Upload Product Image',
      name: 'productImages',
      type: 'file',
      accept: "image/png, image/jpeg",
      placeholder: '',
      desc: '',
      defaultValue: data?.imgUrl,
      multiple: true,
      min: 1,
      max: 5,
      regex: '',
      required: true
    },
    {
      label: 'Enter Product Name',
      name: 'productName',
      type: 'text',
      placeholder: '',
      desc: '',
      defaultValue: data?.productName,
      min: 1,
      max: '',
      regex: '',
      required: true,
      variant: 'standard'
    },
    {
      label: 'Select Category',
      name: 'category',
      keyName: 'categoryId',
      valueName: 'categoryName',
      type: 'autocomplete',
      placeholder: '',
      options: categories,
      defaultValue: data?.category,
      required: true,
      variant: 'standard'
    },
    {
      label: 'Select Brand',
      name: 'brand',
      type: 'autocomplete',
      keyName: 'brandId',
      valueName: 'brandName',
      placeholder: '',
      options: brands,
      defaultValue: data?.brand,
      required: true,
      variant: 'standard'
    }
  ]
  return variantForm
}