import styled from "styled-components";

export const LayoutStyle = styled.div`
  min-height: 100vh;
  background-color: #fff;
  .hide-content{
    overflow: hidden;
    visibility: hidden;
    height: 0px;
  }
`