import styled from "styled-components";

export const SignupStyle = styled.div`
  max-width: 100%;
  padding: 20px;
  padding-top: 50px;
  .mt-30{
    margin-top: 30px;
  }
  margin-bottom: 100px;
`