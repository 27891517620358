import { Link } from "react-router-dom"
import { ImageHorizontalCardStyle } from "./index.style"

function ImageHorizontalCard(props) {
  const {imgUrl,height,imgLinkUrl=''} = props

  return (
    <ImageHorizontalCardStyle align="center" flexWrap="nowrap" height={height}>
      {
        imgLinkUrl?
          <Link to={imgLinkUrl} className="img-container">
            <img className="img" src={imgUrl} />
          </Link>
        :
        <div className="img-container">
          <img className="img" src={imgUrl} />
        </div>
      }
      
      {
        props.children
      }
    </ImageHorizontalCardStyle>
  )
}

export default ImageHorizontalCard