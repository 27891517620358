import { LandingPageStyle } from './index.style'
import { useContext } from 'react'
import APIContext from '../../contexts/apis/context'
import { useEffect } from 'react'
import ShowSnackbar from '../../components/ShowSnackbar'
import { Row } from '../../commonStyles/index.style'
import CustomText from '../../components/CustomText'
import { Button, Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material'
import { useTheme } from 'styled-components'
import { Link } from "react-router-dom";
import Layout from '../../components/layouts/Layout'
import { getOrderStatus } from '../../helpers/utility'

function LandingPage() {
  const { getTotalSalesAmount, getTotalSalesAmountData, getTotalSalesAmountData_l,
    getOrders, getOrdersData, getOrdersData_l, resetState, resetLoaders } = useContext(APIContext)
  const theme = useTheme()

  useEffect(() => {
    getTotalSalesAmount()
    getOrders()
  }, [])

  useEffect(()=>{
    return ()=>{
      //resetting all loaders as when the page is visited again, the loader by default is false
      resetLoaders(['getTotalSalesAmountData','getOrdersData'])
    }
  },[])

  useEffect(() => {
    if (getTotalSalesAmountData && getTotalSalesAmountData.status === 'failed') {
      ShowSnackbar.error({
        text: getTotalSalesAmountData.error,
        visible: true
      })
      resetState('getTotalSalesAmountData')
    }
    if (getOrdersData) {
      if (getOrdersData.status === 'failed') {
        ShowSnackbar.error({
          text: getOrdersData.error,
          visible: true
        })
      }
      resetState('getOrdersData')
    }
  }, [getOrdersData, getTotalSalesAmountData])

  return (
    <Layout title="Home" isLoading={!getOrdersData_l}>
      <LandingPageStyle>
        <Row flexDirection="column" align="center" justify="center" className="section-top">
          <CustomText
            fontColor={theme.color.white}
            fontSize="m1"
            textAlign="center"
          >
            Total Sales
          </CustomText>
          <CustomText
            fontColor={theme.color.white}
            isRupee
            showLoader={!getTotalSalesAmountData_l}
            fontSize="hMax"
            textAlign="center"
            lineHeight="1"
            marginBottom="20px"
          >
            {getTotalSalesAmountData?.data?.data?.supplierSumSP}
          </CustomText>
          {/* <Button sx={{ borderRadius: 12.5, padding: "20px 40px" }} size="large" variant="contained" color="white">
            View Transactions
          </Button> */}
        </Row>
        <Row align="center" justify="center" className='section-middle'>
          {
            getOrdersData?.data?.data?.length > 0 ?
              <>
                <Row justify="space-between" align="center" width="100%">
                  <CustomText
                    fontSize="m2"
                    textAlign="center"
                    fontWeight={600}
                  >
                    Orders
                  </CustomText>
                  <Link to="/orders">
                    <Button size="large" color="primary">
                      More
                    </Button>
                  </Link>
                </Row>
                <Row
                  flexWrap="nowrap"
                  columnGap="15px"
                  className="sliding-orders"
                  align="center"
                >
                  {
                    getOrdersData?.data?.data?.map((op) => {
                      const item = op?.productDetail
                      const variant = op?.variantDetail
                      const orderStatus = getOrderStatus(op.status)
                      return (
                        <Card sx={{ borderRadius: '10px', flexShrink: 0, width: '180px', height: '260px' }} key={op.orderProductId}>
                          <Link to={`/order-detail/${op.orderProductId}`} className="link">
                            <CardActionArea>
                              <CardMedia
                                component="img"
                                height="160"
                                image={item?.imgUrl?.[0]}
                                alt={variant?.variantName}
                              />
                              <CardContent sx={{ padding: '5px', height: '100px' }}>
                                <Row flexDirection="column" justify="space-between" height="100%" align="stretch">
                                  <div>
                                    <CustomText
                                      overflowLength={20}
                                      fontColor={theme.color.primary}
                                      fontSize="m1"
                                    >
                                      {item?.productName}
                                    </CustomText>
                                    <CustomText
                                      fontColor={theme.color.secondary}
                                      fontSize="s3"
                                    >
                                      ({variant?.variantName})
                                    </CustomText>
                                  </div>
                                  <CustomText
                                    fontColor={orderStatus.color}
                                    fontSize="s3"
                                    textAlign="center"
                                  >
                                    {orderStatus.label}
                                  </CustomText>
                                </Row>
                              </CardContent>
                            </CardActionArea>
                          </Link>
                        </Card>
                      )
                    })
                  }
                </Row>
              </>
              :
              <CustomText
                fontSize="m1"
                fontColor={theme.color.dark}
              >
                Ordered Products will appear here !!
              </CustomText>
          }
        </Row>
        {/* <div className="section-last">
          <Link to="/products">
            <Button fullWidth sx={{ borderRadius: 12.5, padding: "15px" }} size="large" variant="contained">
              View Available Products
            </Button>
          </Link>
        </div> */}
      </LandingPageStyle>
    </Layout>
  )
}

export default LandingPage