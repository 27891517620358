import { useCallback, useState } from 'react';
import { Row } from '../../../../commonStyles/index.style';
import { NavbarStyle } from './index.style.js'
import MobileSidebar from './components/MobileSidebar';
import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CustomText from '../../../CustomText';
import { useTheme } from 'styled-components';

function Navbar(props) {
  const [isShowSidebar, setIsShowSidebar] = useState(false)

  const handleToggleSidebar = useCallback(() => {
    setIsShowSidebar((iss) => !iss)
  }, [])

  const theme = useTheme()

  const {title} = props

  return (
    <NavbarStyle>
      <Row justify="space-between" align="center">
        <Row align="center">
          <MenuIcon onClick={handleToggleSidebar}
            fontSize="large"
            className="cursor-pointer"
            htmlColor="white"
          />
          <CustomText
            fontColor={theme.color.white}
            fontSize="h1"
            padLeft="10px"
            marginBottom="0px"
          >
            {title}
          </CustomText>
        </Row>
        {/* <MoreVertIcon
          className="cursor-pointer"
          htmlColor="white"
        /> */}
      </Row>
      <MobileSidebar
        isShowSidebar={isShowSidebar}
        handleToggleSidebar={handleToggleSidebar}
      />
    </NavbarStyle>
  )
}

export default Navbar
