import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Modal, Switch } from "@mui/material"
import { useCallback, useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useTheme } from "styled-components"
import { Divider, Row } from "../../commonStyles/index.style"
import CustomText from "../../components/CustomText"
import DiscountedMRP from "../../components/DiscountedMRP"
import Layout from "../../components/layouts/Layout"
import ShowSnackbar from "../../components/ShowSnackbar"
import SupplierVariantForm from "../../components/SupplierVariantForm"
import APIContext from "../../contexts/apis/context"
import { getProductStatus, getStockColor, getVariantStatus, stringAvatar } from "../../helpers/utility"
import { ProductDetailStyle, VariantModalStyle } from "./index.style"

function ProductDetail() {
  const { productId } = useParams()
  const { getSupplierProductDetail, getSupplierProductDetailData, getSupplierProductDetailData_l,
    updateSupplierVariant, updateSupplierVariantData, updateSupplierVariantData_l,
    resetState, resetLoaders } = useContext(APIContext)

  const [variants, setVariants] = useState([])
  const [isShowModal, setIsShowModal] = useState(false)
  const [selectedVariant, setSelectedVariant] = useState({})
  const theme = useTheme()

  useEffect(() => {
    getSupplierProductDetail(productId)
  }, [])

  useEffect(() => {
    return () => {
      //resetting all loaders as when the page is visited again, the loader by default is false
      resetLoaders(['getSupplierProductDetailData', 'updateSupplierVariantData'])
    }
  }, [])

  useEffect(() => {
    if (getSupplierProductDetailData && getSupplierProductDetailData.status === 'success') {
      setVariants(getSupplierProductDetailData?.data?.data?.variant)
      resetState('getSupplierProductDetailData')
    }
    else if (getSupplierProductDetailData && getSupplierProductDetailData.status === 'failed') {
      ShowSnackbar.error({
        text: getSupplierProductDetailData.error,
        visible: true
      })
      resetState('getSupplierProductDetailData')
    }
    if (updateSupplierVariantData && updateSupplierVariantData.status === 'success') {
      getSupplierProductDetail(productId)
      ShowSnackbar.success({
        text: 'Success',
        visible: true
      })
      resetState('updateSupplierVariantData')
    }
    else if (updateSupplierVariantData && updateSupplierVariantData.status === 'failed') {
      ShowSnackbar.error({
        text: updateSupplierVariantData.error,
        visible: true
      })
      resetState('updateSupplierVariantData')
    }
  }, [getSupplierProductDetailData, updateSupplierVariantData])

  const handleToggleModal = useCallback(() => {
    setIsShowModal((m) => !m)
  }, [])

  const handleSelectVariant = useCallback((v) => {
    setSelectedVariant({
      ...v.supplierVariantData,
      variantName: v.variantName
    })
    handleToggleModal()
  }, [])

  const handleToggleActive = useCallback((v, i) => {
    updateSupplierVariant({
      status: v.supplierVariantData.status === 'ACTIVE' ? 'DISABLED' : 'ACTIVE',
      supplierVariantId: v.supplierVariantData.supplierVariantId
    })
  }, [])

  const handleUpdateVariantCallback = useCallback((status, data) => {
    handleToggleModal()
    if (status === 'success') {
      getSupplierProductDetail(productId)
    }
  }, [productId])

  const productData = getSupplierProductDetailData?.data?.data
  const productStatus = getProductStatus(productData?.supplierProductData.status)
  const variantsData = variants?.filter((v) => v.supplierVariantData)

  return (
    <Layout title="Variants" isLoading={!getSupplierProductDetailData_l}>
      <ProductDetailStyle>
        <Row justify="center">
          <Avatar
            alt={productData?.productName}
            src={productData?.imgUrl?.[0]}
            sx={{ height: 100, width: 100 }}
          />
        </Row>
        <CustomText
          fontColor={theme.color.primary}
          fontSize="m2"
          textAlign="center"
          paddingTop="8px"
        >
          {productData?.productName}
        </CustomText>
        <CustomText
          fontColor={productStatus?.color}
          fontSize="s3"
          textAlign="center"
        >
          ( {productStatus?.label} )
        </CustomText>
        <Divider />
        <div className="items-list">
          <List sx={{ padding: 0 }}>
            {
              variantsData
                .map((v, i) => {
                  const supplierVData = v.supplierVariantData
                  const variantStatus = getVariantStatus(supplierVData.status)
                  const avatarProps = v.imgUrl?.[0] ? 
                  { src: v.imgUrl?.[0], alt: v.variantName } 
                  : 
                  { children: stringAvatar(v.variantName) }
                  return (
                    <div key={supplierVData.supplierVariantId}>
                      <ListItem sx={{ padding: 0 }}>
                        <ListItemAvatar>
                          <Avatar 
                            {...avatarProps}
                          />
                        </ListItemAvatar>
                        <ListItemText primary={
                          <Row flexDirection="column">
                            <CustomText
                              fontSize="m1"
                              marginBottom="0"
                            >
                              {v.variantName}
                            </CustomText>
                            <DiscountedMRP MRP={supplierVData.MRP} SP={supplierVData.SP} discountPercent={supplierVData.discountPercent} />
                            <CustomText
                              fontSize="s2"
                              fontColor={getStockColor(supplierVData.stock)}
                            >
                              Only {supplierVData.stock} left
                            </CustomText>
                          </Row>
                        }
                          secondary={
                            <Row>
                              <CustomText
                                fontColor={theme.color.primary}
                                className="cursor-pointer"
                                onClick={() => handleSelectVariant(v)}
                              >
                                View/Update
                              </CustomText>
                              <CustomText
                                fontColor={variantStatus.color}
                              >
                                &nbsp;&#x2022;&nbsp;{variantStatus.label}
                              </CustomText>
                            </Row>
                          }
                        />
                        <Switch
                          edge="end"
                          onChange={() => handleToggleActive(v, i)}
                          checked={variantStatus.key === 'ACTIVE'}
                        />
                      </ListItem>
                      <Divider />
                    </div>
                  )
                })
            }
          </List>
        </div>
        <Modal
          open={isShowModal}
          onClose={handleToggleModal}
          aria-labelledby="Variant Detail"
        >
          <VariantModalStyle>
            <SupplierVariantForm
              defaultData={selectedVariant}
              supplierVariantId={selectedVariant?.supplierVariantId}
              handleCallback={handleUpdateVariantCallback}
            />
          </VariantModalStyle>
        </Modal>
      </ProductDetailStyle>
    </Layout>
  )
}

export default ProductDetail