import { isEmpty } from "lodash"
import { useRef } from "react"
import { useCallback, useContext, useEffect, useState } from "react"
import ShowSnackbar from "../../components/ShowSnackbar"
import APIContext from "../../contexts/apis/context"
import AddProductForm from "./components/AddProductForm"
import AddProductJobStatus from "./components/AddProductJobStatus"

function AddProduct(){
  const { getLastInventoryJob, getLastInventoryJobData, 
    getLastInventoryJobData_l, updateInventoryJob,
    resetState, resetLoaders
  } = useContext(APIContext)

  const [inventoryStatus, setInventoryStatus] = useState()
  const [isUserNotified, setIsUserNotified] = useState(false)
  const [isFirstLoadDone, setIsFirstLoadDone] = useState(false)

  const handleDoneClick = useCallback(()=>{
    updateInventoryJob({
      notifiedUser: 1,
      jobId: inventoryStatus.jobId
    })
    setIsUserNotified(true)
  },[updateInventoryJob, inventoryStatus])

  const handleGetInventoryJob = useCallback(()=>{
    getLastInventoryJob()
  },[getLastInventoryJob])

  const handleResetInventoryJob = useCallback(()=>{
    getLastInventoryJob()
    setIsUserNotified(false)
  },[getLastInventoryJob])

  useEffect(() => {
    if (getLastInventoryJobData && getLastInventoryJobData.status === 'success') {
      if(!isFirstLoadDone){
        setIsFirstLoadDone(true)
      }
      setInventoryStatus(getLastInventoryJobData?.data?.data)
      resetState('getLastInventoryJobData')
    }
    else if (getLastInventoryJobData && getLastInventoryJobData.status === 'failed') {
      ShowSnackbar.error({
        text: getLastInventoryJobData.error,
        visible: true
      })
      resetState('getLastInventoryJobData')
    }
  }, [getLastInventoryJobData, isFirstLoadDone])

  useEffect(()=>{
    return ()=>{
      //resetting all loaders as when the page is visited again, the loader by default is false
      resetLoaders(['getLastInventoryJobData','updateInventoryJobData'])
    }
  },[])
    
  useEffect(() => {
    handleGetInventoryJob()
  }, [])

  if(isFirstLoadDone && isEmpty(inventoryStatus)){
    //no inventory jobs added yet
    return <AddProductForm handleResetInventoryJob={handleResetInventoryJob}/>
  }
  else if(!isEmpty(inventoryStatus)){
    if(
      (
        (inventoryStatus?.status === 'PENDING') || 
        (inventoryStatus?.status === 'ACTIVE') || 
        ((inventoryStatus?.status === 'COMPLETED') && !inventoryStatus?.notifiedUser) || 
        ((inventoryStatus?.status === 'PARTIAL_COMPLETE') && !inventoryStatus?.notifiedUser)
      ) && !isUserNotified
    ){
      return (
        <AddProductJobStatus 
          handleGetInventoryJob={handleGetInventoryJob} 
          inventoryStatus={inventoryStatus}
          handleDoneClick={handleDoneClick}
        />
      )
    }else{
      return <AddProductForm handleResetInventoryJob={handleResetInventoryJob}/>
    }
  }
  return null
}

export default AddProduct