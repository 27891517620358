import { Avatar } from '@mui/material'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { Divider, Row } from '../../../../../../commonStyles/index.style'
import APIContext from '../../../../../../contexts/apis/context'
import LoginContext from '../../../../../../contexts/login/context'
import { getUserProfileUtil, stringAvatar } from '../../../../../../helpers/utility'
import Button from '../../../../../Button'
import CustomText from '../../../../../CustomText'
import ShowSnackbar from '../../../../../ShowSnackbar'
import { MobileSidebarStyle } from './index.style'

function MobileSidebar(props) {
  const navigate = useNavigate()
  const { handleToggleSidebar, isShowSidebar } = props
  const [userProfile, setUserProfile] = useState({})
  const theme = useTheme()
  const { logout, logoutData, resetState } = useContext(APIContext)
  const {handleUpdateLoginState} = useContext(LoginContext)

  const handleLogout = useCallback(() => {
    handleToggleSidebar()
    logout()
  }, [logout, handleToggleSidebar])

  useEffect(() => {
    (async () => {
      const userProfileTemp = await getUserProfileUtil()
      setUserProfile(userProfileTemp)
    })()
  }, [])

  useEffect(() => {
    if (logoutData && logoutData.status === 'success') {
      navigate('/')
      handleUpdateLoginState(false)
      resetState('logoutData')
    } else if (logoutData && logoutData.status === 'failed') {
      ShowSnackbar.error({
        text: logoutData.error,
        visible: true
      })
      resetState('logoutData')
    }
  }, [logoutData])

  const handleLinkChange = useCallback((ln) => {
    handleToggleSidebar()
    navigate(ln)
  }, [])

  const handleProfileClick = useCallback(() => {
    // navigate('/profile')
    handleToggleSidebar()
  }, [handleToggleSidebar])

  const NavbarItems = useMemo(() => {
    let items = [[{
      link: '/',
      label: 'Home',
      type: 'link'
    }], [{
      link: '/orders',
      label: 'Orders',
      type: 'link'
    }], [{
      link: '/products',
      label: 'Products',
      type: 'link'
    }],
    [{
      link: '/product-req-list',
      label: 'Product Requests',
      type: 'link'
    }], 
    [{
      label: 'Logout',
      type: 'button',
      onClick: handleLogout
    }]]
    return items
  }, [handleLogout])

  const renderNavarItem = (n) => {
    switch (n.type) {
      case 'button':
        return (
          <Button
            color={theme?.color?.primary}
            onClick={n.onClick}
            fontSize="18px"
            padding="5px 10px"
            borderRadius="5px"
          >
            {n.label}
          </Button>
        )
      case 'link':
        return (
          <CustomText
            fontSize="m2"
            className="cursor-pointer"
            onClick={() => { handleLinkChange(n.link) }}
          >
            {n.label}
          </CustomText>
        )
    }
  }

  return (
    <MobileSidebarStyle isShowSidebar={isShowSidebar}>
      <Row justify="right">
        <CustomText
          fontSize="h3"
          padRight="10px"
          className="cursor-pointer"
          onClick={handleToggleSidebar}
        >
          X
        </CustomText>
      </Row>
      <Row flexDirection="column">
        <Row flexDirection="column"
          className='sidebarTop'
          justify="end"
          align="center"
        >
          <Row justify="center">
            <Avatar
              children={stringAvatar(userProfile?.companyName)}
              sx={{ bgcolor: theme.color.blue.main, height: 100, width: 100 }}
            />
          </Row>
          <CustomText
            fontSize="m3"
            padLeft="10px"
            paddingTop="20px"
            padRight="10px"
            onClick={handleProfileClick}
            // className="cursor-pointer"
          >
            {userProfile?.companyName}
          </CustomText>
          <CustomText
            fontSize="m1"
            padLeft="10px"
            padRight="10px"
          >
            {userProfile?.hostname}
          </CustomText>
        </Row>
        <Divider bottom="0px" />
        <div className='itemsContainer'>
          {
            NavbarItems.map((ns, i) => {
              return (
                <div key={i}>
                  {
                    (i !== 0) &&
                    <Divider top="0px" bottom="0px" />
                  }
                  {
                    ns.map((n) => {
                      return (
                        <div className='SidebarItem' key={n.label}>
                          {renderNavarItem(n)}
                        </div>
                      )
                    })
                  }
                </div>
              )
            })
          }
        </div>
      </Row>
    </MobileSidebarStyle>
  )
}

export default MobileSidebar