import Navbar from './Navbar'
import Loader from '../../Loader'
import { LayoutStyle } from './index.style'

function Layout(props) {
  const { isLoading, loadingMsg, title } = props
  return (
    <LayoutStyle>
      <div className="sticky-top" style={{ backgroundColor: '#fff', width: '100%', zIndex: '2' }}>
        <Navbar title={title}/>
      </div>
      {
        isLoading &&
        <Loader loadingMsg={loadingMsg}/>
      }
      {
        /*
        we are not adding a normal ternary operator here(just setting visiblility as none(check class hide-content))
        because it will cause rerender for all the rendered componented
        and may cause infinite loop 
        */
      }
      <div className={isLoading ? 'hide-content' : ''} style={{ minHeight: '65vh', maxHeight: '95vh', overflow:'scroll' }}>
        {
          props.children
        }
      </div>
    </LayoutStyle>
  )
}

export default Layout