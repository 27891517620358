import { Alert, Button } from "@mui/material";
import { useEffect } from "react";
import { Row } from "../../../../commonStyles/index.style";
import CircularProgressWithLabel from "../../../../components/CircularProgressWithLabel";
import Layout from "../../../../components/layouts/Layout";
import { AddProductJobStatusStyle } from './index.style'
import { useRef } from "react";

function AddProductJobStatus(props) {
  const { handleGetInventoryJob, inventoryStatus, handleDoneClick } = props
  const inventoryInterval = useRef()

  useEffect(() => {
    inventoryInterval.current = setInterval(() => {
      handleGetInventoryJob()
    }, 5000)
    return () => {
      clearInterval(inventoryInterval.current)
    }
  }, [handleGetInventoryJob])

  useEffect(()=>{
    if(['COMPLETED','PARTIAL_COMPLETE'].includes(inventoryStatus.status)){
      clearInterval(inventoryInterval.current)
    }
  },[inventoryStatus])

  const renderJobStatus = () => {
    switch (inventoryStatus.status) {
      case 'COMPLETED':
        return (
          <>
            <Alert sx={{ marginBottom: '20px' }} severity="success">
              Products successfully added
            </Alert>
            <Row justify="center">
              <Button size="large" variant="contained" onClick={handleDoneClick}>
                Done
              </Button>
            </Row>
          </>
        )
      case 'PARTIAL_COMPLETE':
        return (
          <>
            <Alert sx={{ marginBottom: '20px' }} severity="error">
              Following products could not be added
              <div>
                {
                  inventoryStatus?.response &&
                  JSON.parse(inventoryStatus.response).map(p => p.productName).join(", ")
                }
              </div>
            </Alert>
            <Row justify="center">
              <Button size="large" variant="contained" onClick={handleDoneClick}>
                Done
              </Button>
            </Row>
          </>
        )
      case 'PENDING':
      case 'ACTIVE':
        return (
          <>
            <Alert sx={{ marginBottom: '20px' }} severity="info">
              Feel free to do other stuff, while we add your products : )
            </Alert>
            <Row justify="center">
              <CircularProgressWithLabel value={inventoryStatus?.completePercent} />
            </Row>
          </>
        )
    }
  }

  return (
    <Layout title="Inventory status">
      <AddProductJobStatusStyle>
        <div>
          {renderJobStatus()}
        </div>
      </AddProductJobStatusStyle>
    </Layout>
  )
}

export default AddProductJobStatus