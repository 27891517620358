import React from 'react';
import ReactDOM from 'react-dom/client';
import MyApp from './routes/App';
// import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

//NOTE:- <React.StrictMode> intentionally renderes it's children twice in development build, 
//so it's easier to identify re-render bugs, IT won't happen in production so RELAX.

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <MyApp />
  // {/* </React.StrictMode> */}
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

serviceWorker.register()
