import styled from "styled-components";

export const PrivacyPolicyStyled = styled.div`
margin: auto;
max-width: 800px;
.ml-2.text-content {
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.ml-2.text-content h1 {
  color: #333;
  margin-bottom: 10px;
}

.ml-2.text-content h5 {
  color: #666;
  margin-bottom: 20px;
}

.ml-2.text-content p {
  line-height: 1.5;
  margin-bottom: 15px;
}

.ml-2.text-content ul {
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 15px;
}

.ml-2.text-content li {
  line-height: 1.5;
}

.ml-2.text-content strong {
  font-weight: bold;
}

.ml-2.text-content b {
  font-weight: bold;
}

.ml-2.text-content i {
  font-style: italic;
}

.ml-2.text-content h3 {
  color: #333;
  margin-top: 20px;
  margin-bottom: 10px;
}

.ml-2.text-content h3 + p {
  margin-top: 10px;
}

.ml-2.text-content h3 + ul {
  margin-top: 10px;
}

.ml-2.text-content h3 + p,
.ml-2.text-content h3 + ul {
  margin-bottom: 15px;
}

.ml-2.text-content p:last-child {
  margin-bottom: 0;
}

`