import styled from "styled-components";
import { Row } from "../../commonStyles/index.style";

export const ImageHorizontalCardStyle = styled(Row)`
.img-container{
  flex-shrink: 0;
  max-width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 10px;
}

.img{
  max-width: 100%;
  max-height: ${props=>props.height || '250px'};
}

`