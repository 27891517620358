import FullScreenDialog from '../../../../components/FullScreenDialogue'
import { InventoryConfirmationPopupStyle } from './index.style'
import { List, ListItem, ListItemText } from '@mui/material'
import CustomText from '../../../../components/CustomText'
import { Divider, Row } from '../../../../commonStyles/index.style'
import { colorPallet } from '../../../../commonStyles/colors'
import DiscountedMRP from '../../../../components/DiscountedMRP'
import { getStockColor } from '../../../../helpers/utility'

function InventoryConfirmationPopup(props) {
  const { showConfirmationDialogue, handleToggleConfirmationDialogue, handleUpdateInventory, finalInventory } = props

  return (
    <InventoryConfirmationPopupStyle>
      <FullScreenDialog
        open={showConfirmationDialogue}
        handleDialogeToggle={handleToggleConfirmationDialogue}
        title="Added Products"
        handleDone={handleUpdateInventory}
      >
        <List>
          {
            finalInventory?.map((product) => (
              <div key={product.productId}>
                <ListItem>
                  <ListItemText primary={
                    <CustomText
                      width="100%"
                      fontSize="m2"
                      marginBottom="0px"
                    >
                      {product.productName}
                    </CustomText>
                  } />
                </ListItem>
                  <List sx={{ paddingTop: 0 }}>
                    {
                      product?.variant?.map((v) => {
                        return (
                        <div key={v.variantId}>
                          <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
                            <ListItemText
                              primary={
                                <Row justify="space-between" align="center">
                                  <CustomText
                                    fontSize="m1"
                                    paddingTop="5px"
                                  >
                                    {v.variantName}
                                  </CustomText>
                                  <CustomText
                                    fontColor={colorPallet.green.main}
                                    fontSize="s3"
                                  >
                                    ADDED
                                  </CustomText>
                                </Row>
                              }
                              secondary={
                                <Row flexDirection="column">
                                  <DiscountedMRP
                                    sizeLabel="s3"
                                    MRP={v.MRP}
                                    SP={v.SP}
                                  />                                    
                                  <CustomText
                                    fontColor={getStockColor(v.stock)}
                                    fontSize="s2"
                                  >
                                    Stock = {v.stock}
                                  </CustomText>
                                </Row>
                              }
                            />
                          </ListItem>
                        </div>
                      )})
                    }
                  </List>
                <Divider/>
              </div>
            ))
          }
        </List>
      </FullScreenDialog>
    </InventoryConfirmationPopupStyle>
  )
}

export default InventoryConfirmationPopup