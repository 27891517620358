import { SignupStyle } from './index.style'
import Form from '../../components/Form'
import { Alert, Avatar, Button, InputAdornment, TextField } from '@mui/material'
import { useCallback, useRef } from 'react'
import APIContext from '../../contexts/apis/context'
import { useEffect } from 'react'
import ShowSnackbar from '../../components/ShowSnackbar'
import { useState } from 'react'
import { useContext } from 'react'
import { Row } from '../../commonStyles/index.style'
import { useNavigate } from 'react-router-dom'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CustomText from '../../components/CustomText'

function Signup() {
  const submitFormRef = useRef('')
  const navigate = useNavigate()

  const { signup, signupData, signupData_l,
    validateOtp, validateOtpData, validateOtpData_l,
    resetState, resetLoaders
  } = useContext(APIContext)

  const [showOtpScreen, setShowOtpScreen] = useState(false)
  const [usefulSignupData, setUsefulSignupData] = useState()

  const handleSubmit = useCallback(async (e) => {
    const response = submitFormRef.current(e)
    if (response) {
      if(showOtpScreen){
        validateOtp({
          token: usefulSignupData?.token,
          scenario: 'signup',
          ...response
        })
        //send Validate otp req
      }else{
        setUsefulSignupData(response)
        signup(response)
      }
    }
  }, [showOtpScreen, signupData, usefulSignupData])

  useEffect(() => {
    return () => {
      //resetting all loaders as when the page is visited again, the loader by default is false
      resetLoaders(['signupData','validateOtpData'])
    }
  }, [])

  useEffect(() => {
    if (signupData && signupData.status === 'success') {
      setShowOtpScreen(true)
      setUsefulSignupData({
        ...usefulSignupData,
        ...signupData?.data
      })
      resetState('signupData')
    }
    else if (signupData && signupData.status === 'failed') {
      ShowSnackbar.error({
        text: signupData.error,
        visible: true
      })
      resetState('signupData')
    }
    if (validateOtpData && validateOtpData.status === 'success') {
      ShowSnackbar.success({
        text: 'Signup Successfull, please login to continue',
        visible: true
      })
      navigate('/login')
      resetState('validateOtpData')
    }
    else if (validateOtpData && validateOtpData.status === 'failed') {
      ShowSnackbar.error({
        text: validateOtpData.error,
        visible: true
      })
      resetState('validateOtpData')
    }
  }, [signupData, validateOtpData, usefulSignupData])

  const getSubmitMethod = useCallback((submitMethod) => {
    submitFormRef.current = submitMethod
  }, [])

  const handleLogin = ()=>{
    navigate('/login')
  }

  const signupForm = getSignupForm()
  const otpForm = getOtpForm()

  return (
    <SignupStyle>
      <>
        <Row justify="center">
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
        </Row>
        <Alert sx={{ marginBottom: '20px' }} severity="warning">
          You don't have an account, please signup to continue
        </Alert>
        <div className='mt-30'>
          {
            showOtpScreen?
            <Form
              key="otp-form"
              formData={otpForm}
              getSubmitMethod={getSubmitMethod}
            />
            :
            <Form
              key="signup-form"
              formData={signupForm}
              getSubmitMethod={getSubmitMethod}
            />
          }
    
          <Button fullWidth sx={{ marginBottom: '20px', marginTop: "30px" }}
            size="large"
            variant="contained"
            onClick={handleSubmit}
          >
            Signup
          </Button>
          <CustomText
            fontSize="s3"
          >
            Already have an account?
          </CustomText>
          <Button sx={{ padding: 0 }} variant="text" onClick={handleLogin}>
            Login Here
          </Button>

        </div>
      </>
    </SignupStyle>
  )
}

export default Signup

const getOtpForm = ()=>{
  let otpForm = [
    {
      label: 'Enter OTP',
      name: 'otp',
      type: 'number',
      placeholder: '',
      desc: '',
      min: 0,
      max: 999999,
      required: true,
      variant: 'outlined'
    }
  ]
  return otpForm
}

const getSignupForm = () => {
  let signupForm = [
    {
      label: 'Enter Phone no',
      name: 'phone',
      type: 'mobile',
      placeholder: 'Mobile',
      defaultValue: '',
      regex: '',
      required: true,
      variant: 'outlined'
    }, {
      label: 'Enter password',
      name: 'password',
      type: 'password',
      placeholder: 'password',
      desc: '',
      defaultValue: '',
      min: 3,
      max: 30,
      regex: '',
      required: true,
      variant: 'outlined'
    },
    {
      label: 'Enter company name',
      name: 'companyName',
      type: 'text',
      placeholder: '',
      desc: '',
      min: 3,
      max: 50,
      regex: '',
      required: true,
      variant: 'outlined'
    },
    {
      label: 'Enter site url',
      name: 'companySite',
      type: 'text',
      placeholder: '',
      desc: 'The name for your new website (eg abc.com)',
      inputProps: {
          endAdornment: <InputAdornment position="end">.gowinkl.com</InputAdornment>
      },
      regexError: `Sorry, hostname must be 4-20 character,start with a letter, contain only letters, numbers, underscores, and hyphens.`,
      regex: /^[a-zA-Z][a-zA-Z0-9_-]{3,19}$/,
      required: true,
      variant: 'outlined'
    }
  ]
  return signupForm
}