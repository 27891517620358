import { Alert, Avatar, Button, Fab, List, ListItem, ListItemAvatar, ListItemText, Modal, Switch } from "@mui/material"
import { Divider, Row } from "../../commonStyles/index.style"
import CustomText from "../../components/CustomText"
import Layout from "../../components/layouts/Layout"
import { AddNewVariantStyle, VariantDataModalStyle } from "./index.style"
import { useParams } from "react-router-dom"
import { useContext, useRef } from "react"
import APIContext from "../../contexts/apis/context"
import ShowSnackbar from "../../components/ShowSnackbar"
import { useState } from "react"
import { useTheme } from "styled-components"
import { useCallback } from "react"
import { useEffect } from "react"
import AddIcon from '@mui/icons-material/Add';
import Form from "../../components/Form"
import { getProductReqStatus, getVariantReqStatus, uploadImagesToAwsSigned } from "../../helpers/utility"
import CircularProgressWithLabel from "../../components/CircularProgressWithLabel"

function AddNewVariant() {
  const { productReqId } = useParams()
  const submitFormRef = useRef('')
  const submittedVariantData = useRef()
  const [imageUploadPercent, setImageUploadPercent] = useState(0)

  const theme = useTheme()
  const [isShowModal, setIsShowModal] = useState(false)

  const {
    getProductRequestDetail, getProductRequestDetailData, getProductRequestDetailData_l,
    upsertNewVariantRequest, upsertNewVariantRequestData, upsertNewVariantRequestData_l,
    resetState, resetLoaders
  } = useContext(APIContext)

  const [productReqDetailData, setProductReqDetailData] = useState({})

  const handleSubmit = useCallback(async (e) => {
    const response = submitFormRef.current(e)
    if (response) {
      if(response?.variantImages?.length > 0){
        uploadImagesToAwsSigned(response.variantImages, (percent, uploadedFilePaths) => {
          setImageUploadPercent(percent)
          if (percent === 100) {
            submittedVariantData.current = {
              ...response,
              productReqId: parseInt(productReqId),
              variantImages: uploadedFilePaths
            }
            upsertNewVariantRequest(submittedVariantData.current)
          }
        }, (err) => {
          ShowSnackbar.error({
            text: "Some error occured while uploading images",
            visible: true
          })
        })
      }else{
        submittedVariantData.current = {
          ...response,
          productReqId: parseInt(productReqId)
        }
        upsertNewVariantRequest(submittedVariantData.current)
      }
    }
  }, [])

  const handleToggleModal = useCallback(() => {
    setIsShowModal((m) => !m)
  }, [])

  const getSubmitMethod = useCallback((submitMethod) => {
    submitFormRef.current = submitMethod
  }, [])

  useEffect(() => {
    if (productReqId) {
      getProductRequestDetail(productReqId)
    }
  }, [productReqId])

  useEffect(() => {
    if (getProductRequestDetailData && getProductRequestDetailData.status === 'success') {
      setProductReqDetailData(getProductRequestDetailData.data.data)
      resetState('getProductRequestDetailData')
    }
    else if (getProductRequestDetailData && getProductRequestDetailData.status === 'failed') {
      ShowSnackbar.error({
        text: getProductRequestDetailData.error,
        visible: true
      })
      resetState('getProductRequestDetailData')
    }
    if (upsertNewVariantRequestData && upsertNewVariantRequestData.status === 'success') {
      setImageUploadPercent(0)
      //adding submitted variant details here if variant adding request is successfull
      // This is just to avoid calling getProductReqDetail API to get updated data
      let updatedVariantData = [
        {
          ...submittedVariantData.current,
          imgUrl: submittedVariantData.current.variantImages,
          status: 'PENDING'
        }
      ]
      if(productReqDetailData?.variantsData){
        updatedVariantData = [
          ...updatedVariantData,
          ...productReqDetailData?.variantsData
        ]
      }
      setProductReqDetailData({
        ...productReqDetailData,
        variantsData: updatedVariantData
      })
      ShowSnackbar.success({
        text: "Variant request placed successfully",
        visible: true
      })
      handleToggleModal()
      resetState('upsertNewVariantRequestData')
    }
    else if (upsertNewVariantRequestData && upsertNewVariantRequestData.status === 'failed') {
      ShowSnackbar.error({
        text: upsertNewVariantRequestData.error,
        visible: true
      })
      resetState('upsertNewVariantRequestData')
    }
  }, [getProductRequestDetailData, upsertNewVariantRequestData])

  useEffect(() => {
    return () => {
      //resetting all loaders as when the page is visited again, the loader by default is false
      resetLoaders(['getProductRequestDetailData','upsertNewVariantRequestData'])
    }
  }, [])

  const formData = getAddNewVariantForm({})
  const productStatus = getProductReqStatus(productReqDetailData.status)
  return (
    <Layout title="Add New Variant" isLoading={!getProductRequestDetailData_l}>
      <AddNewVariantStyle>
        {
           imageUploadPercent > 0 ?
           <>
             <Alert sx={{ marginBottom: '20px' }} severity="warning">
               Don't change the page while we add the variant for you
             </Alert>
             <Row justify="center">
               <CircularProgressWithLabel value={imageUploadPercent} />
             </Row>
           </>
          :
          <>
            <Row justify="center">
              <Avatar
                alt={productReqDetailData?.productName}
                src={productReqDetailData?.imgUrl?.[0]}
                sx={{ height: 100, width: 100 }}
              />
            </Row>
            <CustomText
              fontColor={theme.color.primary}
              fontSize="m2"
              textAlign="center"
              paddingTop="8px"
            >
              {productReqDetailData?.productName}
            </CustomText>
            <CustomText
              fontColor={productStatus?.color}
              fontSize="s3"
              textAlign="center"
            >
              ( {productStatus?.label} )
            </CustomText>
            <Divider />
            {
              (productReqDetailData?.variantsData?.length > 0) ?
                <div className="items-list">
                  <List sx={{ padding: 0 }}>
                    {
                      productReqDetailData?.variantsData?.map((v) => {
                        const variantReqStatus = getVariantReqStatus(v.status)
                        return (
                          <div key={v.variantId}>
                            <ListItem sx={{ padding: 0 }}>
                              <ListItemAvatar>
                                <Avatar alt={v.variantName} src={v.imgUrl?.[0] ? v.imgUrl?.[0] : {}} />
                              </ListItemAvatar>
                              <ListItemText primary={
                                <Row flexDirection="column">
                                  <CustomText
                                    fontSize="m1"
                                    marginBottom="0"
                                  >
                                    {v.variantName}
                                  </CustomText>
                                </Row>
                              }
                                secondary={
                                  <Row>
                                    <CustomText
                                      fontColor={variantReqStatus.color}
                                    >
                                      {variantReqStatus.label}
                                    </CustomText>
                                  </Row>
                                }
                              />
                            </ListItem>
                            <Divider />
                          </div>
                        )
                      })
                    }
                  </List>
                </div>
                :
                <Alert sx={{ marginBottom: '20px' }} severity="warning">
                  No variants added yet, click the + button in the right corner to get started
                </Alert>
            }
            <div className='add-icon'>
              <Fab color="primary" aria-label="add-icon" onClick={handleToggleModal}>
                <AddIcon />
              </Fab>
            </div>
            <Modal
              open={isShowModal}
              onClose={handleToggleModal}
            >
              <VariantDataModalStyle>
                <Form
                  formData={formData}
                  getSubmitMethod={getSubmitMethod}
                />
                <Button fullWidth sx={{ marginBottom: '20px', marginTop: "30px" }}
                  size="large"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Add Variant
                </Button>
              </VariantDataModalStyle>
            </Modal>
          </>
        }
      </AddNewVariantStyle>
    </Layout>
  )
}

export default AddNewVariant


const getAddNewVariantForm = ({ data }) => {
  let variantForm = [
    {
      label: 'Upload Variant Image',
      name: 'variantImages',
      type: 'file',
      accept: "image/png, image/jpeg",
      placeholder: '',
      desc: '',
      defaultValue: data?.imgUrl,
      multiple: true,
      min: 1,
      max: 5,
      regex: ''
    },
    {
      label: 'Enter Variant Name',
      name: 'variantName',
      type: 'text',
      placeholder: '',
      desc: '',
      defaultValue: data?.variantName,
      min: 1,
      max: '',
      regex: '',
      required: true,
      variant: 'standard'
    }
  ]
  return variantForm
}