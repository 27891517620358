import styled from 'styled-components'

export const ShowSnackbarStyle = styled.div`
  position: fixed;
  bottom: 6%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 999999999;
  visibility: ${props=>props.visible? 'visible':'hidden'}; /* Hidden by default. Visible on click */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  bottom: 60px;

  .snack-bar-box {
    background-color: ${props => props.error ? props.theme.color.red.main : props.theme.color.blue.main};
    border-radius: 4px;
    padding: 10px;
    margin: 0 20px;
    box-shadow: 10px 10px 15px -6px rgba(0,0,0,0.25);
  }
`
