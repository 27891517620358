import { ProductsStyle } from './index.style'
import { useContext, useRef, useState } from 'react'
import APIContext from '../../contexts/apis/context'
import { useEffect } from 'react'
import ShowSnackbar from '../../components/ShowSnackbar'
import { useCallback } from 'react'
import { Avatar, CircularProgress, Fab, InputAdornment, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Switch, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import Layout from '../../components/layouts/Layout'
import ChipFilters from '../../components/ChipFilters'
import CustomText from '../../components/CustomText'
import { Row } from '../../commonStyles/index.style'
import { useTheme } from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { getProductStatus, PRODUCT_STATUS } from '../../helpers/utility'
import InfiniteScroll from '../../components/InfiniteScroll'
import AddIcon from '@mui/icons-material/Add';

const PRODUCT_FILTERS_ARRAY = Object.keys(PRODUCT_STATUS).map((p) => (getProductStatus(p)))

function Products() {
  const { getSupplierProducts, getSupplierProductsData, getSupplierProductsData_l,
    updateSupplierProduct, updateSupplierProductData, updateSupplierProductData_l,
    resetState, resetLoaders
  } = useContext(APIContext)

  const [isNoMoreData, setIsNoMoreData] = useState(false)
  const navigate = useNavigate()
  const [products, setProducts] = useState([])
  const pageSize = useRef(50)
  const pageNumber = useRef(0)
  const statusFilters = useRef([])
  const searchedName = useRef('')
  const toggledSupplierProductId = useRef()

  const theme = useTheme()

  const toggleProductStatusLocalState = useCallback(() => {
    const supplierProductId = toggledSupplierProductId.current
    const newProducts = products.map((p) => {
      if (p.supplierProductId !== supplierProductId) {
        return p
      } else {
        return {
          ...p,
          status: p.status === 'ACTIVE' ? 'DISABLED' : 'ACTIVE',
        }
      }
    })
    setProducts(newProducts)
  }, [products])

  const handleGetSupplierProducts = useCallback(() => {
    getSupplierProducts({
      pageNumber: pageNumber.current,
      pageSize: pageSize.current,
      status: statusFilters.current,
      productName: searchedName.current
    })
    pageNumber.current = pageNumber.current + 1
  }, [pageNumber.current, pageSize.current,
  statusFilters.current, searchedName.current
  ])

  useEffect(() => {
    handleGetSupplierProducts()
  }, [])
  
  useEffect(()=>{
    return ()=>{
      //resetting all loaders as when the page is visited again, the loader by default is false
      resetLoaders(['getSupplierProductsData','updateSupplierProductData'])
    }
  },[])

  useEffect(() => {
    if (getSupplierProductsData && getSupplierProductsData.status === 'success') {


      setProducts([
        ...products,
        ...getSupplierProductsData?.data?.data
      ])
      if (getSupplierProductsData?.data?.data?.length === 0) {
        setIsNoMoreData(true)
      }
      resetState('getSupplierProductsData')
    }
    else if (getSupplierProductsData && getSupplierProductsData.status === 'failed') {
      ShowSnackbar.error({
        text: getSupplierProductsData.error,
        visible: true
      })
      resetState('getSupplierProductsData')
    }
    if (updateSupplierProductData && updateSupplierProductData.status === 'success') {
      toggleProductStatusLocalState()
      ShowSnackbar.success({
        text: 'Success',
        visible: true
      })
      resetState('updateSupplierProductData')
    }
    else if (updateSupplierProductData && updateSupplierProductData.status === 'failed') {
      ShowSnackbar.error({
        text: updateSupplierProductData.error,
        visible: true
      })
      resetState('updateSupplierProductData')
    }
  }, [getSupplierProductsData, updateSupplierProductData, 
    toggleProductStatusLocalState, products
  ])

  const handleChange = useCallback((e) => {
    const val = e.target.value
    pageNumber.current = 0
    searchedName.current = val
    setProducts([])
    handleGetSupplierProducts()
  }, [handleGetSupplierProducts])

  const handleFiltersChange = useCallback((filters) => {
    setIsNoMoreData(false)
    const fArray = []
    Object.keys(filters).forEach((f) => {
      if (filters[f]) {
        fArray.push(PRODUCT_FILTERS_ARRAY[f].key)
      }
    })
    statusFilters.current = fArray
    //resetting pageNumber whenever filter changes
    pageNumber.current = 0
    setProducts([])
    handleGetSupplierProducts()
  }, [])

  const handleOnEndReach = useCallback(() => {
    handleGetSupplierProducts()
  }, [pageNumber, handleGetSupplierProducts])

  const handleToggleActive = useCallback((p, i) => {
    toggledSupplierProductId.current = p.supplierProductId
    updateSupplierProduct({
      status: p.status === 'ACTIVE' ? 'DISABLED' : 'ACTIVE',
      supplierProductId: p.supplierProductId
    })
  }, [])

  const handleAddClick = useCallback(() => {
    navigate('/add-product')
  }, [])

  return (
    <Layout title="Products" isLoading={updateSupplierProductData_l===undefined?false:!updateSupplierProductData_l}>
      <ProductsStyle>
        <TextField
          label="Search"
          name='search'
          onChange={handleChange}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {!getSupplierProductsData_l ? <CircularProgress color="inherit" size={20} /> : null}
              </>
            ),
          }}
        />
        <ChipFilters className="filters" filters={PRODUCT_FILTERS_ARRAY} onChange={handleFiltersChange} />
        <div className="items-list">

          <List sx={{ padding: 0 }}>
            <InfiniteScroll OnEndReach={handleOnEndReach} isNoMoreData={isNoMoreData} rootMargin="10px">
              {
                products.map((p, i) => {
                  const productStatus = getProductStatus(p.status)
                  return (
                    <ListItem key={p.productId} sx={{ padding: 0 }}>

                      <ListItemAvatar>
                        <Avatar alt={p.productName} src={p.imgUrl?.[0]} />
                      </ListItemAvatar>
                      <ListItemText primary={p.productName}
                        secondary={
                          <Row>
                            <Link to={`/product/${p.productId}`}>
                              <CustomText
                                fontColor={theme.color.primary}
                                className="cursor-pointer"
                              >
                                View / Edit
                              </CustomText>
                            </Link>
                            <CustomText
                              fontColor={productStatus.color}
                            >
                              &nbsp;&#x2022;&nbsp;{productStatus.label}
                            </CustomText>
                          </Row>
                        }
                      />
                      <Switch
                        edge="end"
                        onChange={() => handleToggleActive(p, i)}
                        checked={productStatus.key === 'ACTIVE'}
                      />
                    </ListItem>
                  )
                })
              }
            </InfiniteScroll>
          </List>
          {
            !getSupplierProductsData_l &&
            <Row justify="center" align="center">
              <CircularProgress color="inherit" />
            </Row>
          }
        </div>
        <div className='add-icon'>
          <Fab color="primary" aria-label="add-icon" onClick={handleAddClick}>
            <AddIcon />
          </Fab>
        </div>
      </ProductsStyle>
    </Layout>
  )
}

export default Products