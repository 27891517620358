import 'bootstrap/dist/css/bootstrap.min.css';
import LoginProvider from '../contexts/login/Provider';
import APIProvider from '../contexts/apis/Provider';
import { GlobalStyled } from './global.style';
import GlobalProvider from '../contexts/global/Provider';
import ShowSnackbar from '../components/ShowSnackbar';
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { BrowserRouter as Router } from "react-router-dom";
import RoutesContainer from '.';

function MyApp() {
  return (
    <>
      <GlobalStyled />
      <Router>
        <GlobalProvider>
        <ShowSnackbar />
          <APIProvider>
            <LoginProvider>
              <RoutesContainer />
            </LoginProvider>
          </APIProvider>
        </GlobalProvider>
      </Router>
    </>
  )
}

export default MyApp;
