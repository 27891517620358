import styled from "styled-components";

export const ProductDetailStyle = styled.div`
  padding: 10px;
`
export const VariantModalStyle = styled.div`
  padding: 20px;
  background-color: ${props=>props.theme.color.white};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
`