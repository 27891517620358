import styled from "styled-components";
import { colorPallet } from "../../commonStyles/colors";
import { Box } from "../../commonStyles/index.style";

export const ButtonStyled = styled(Box)`
  display: ${props=>props.display || 'static'};
  .button {
    width: ${props=>props.width? props.width:'inherit'};
    padding: ${props=>props.padding? props.padding : '10px 30px'};
    margin: ${props=>props.margin? props.margin : '10px 0px 0px 0px'};
    cursor: pointer;
    font-size: ${props=>props.fontSize? props.fontSize : '24px'};
    border: none;
    background-color: ${props=>props.styleType === 'outlined'?'transparent':`${props.color}`};
    border: ${props=>props.styleType === 'outlined'?`1px solid ${props.color}`:'none'};
    border-radius: ${props=>props.borderRadius};
    color: ${props=>props.styleType === 'outlined'?`${props.color}`:`${colorPallet.white}`};
    font-weight: bold;
    letter-spacing: 2px;
    transition: background-color .4s ease-out;
    outline: none;
  }
  .button:hover {
    background-color: ${props=>props.styleType === 'outlined'? props.color:`${props.hoverColor}`};
    color: ${colorPallet.white};
  }
  .disabled{
    background-color: ${props=>props.theme?.color?.disabled};
    cursor: auto;
  }
  .disabled:hover{
    background-color: ${props=>props.theme?.color?.disabled};
    cursor: arrow;
  }
`