import { Row } from "../../commonStyles/index.style"
import CustomText from "../CustomText"
import { LoaderStyle } from "./index.style"
import { CircularProgress } from "@mui/material"
import { useTheme } from "styled-components"

function Loader(props) {
  const { loadingMsg } = props
  const theme = useTheme()

  return (
    <LoaderStyle>
      <Row justify="center" align="center" flexDirection="column">
        <CircularProgress color="inherit" size={20} />
        {
          props.loadingMsg &&
          <CustomText
            fontSize="m2"
            fontColor={theme.color.primary}
            fontWeight={500}
            paddingTop="10px"
          >
            {loadingMsg}
          </CustomText>
        }
      </Row>
    </LoaderStyle>
  )
}

export default Loader