import { Chip } from "@mui/material";
import { useState } from "react";
import { Row } from "../../commonStyles/index.style";
import { ChipFiltersStyle } from "./index.style";

function ChipFilters(props) {
  const { filters, onChange, className } = props
  const [selectedFilters, setSelectedFilters] = useState({})

  const handleChipClick = (i) => {
    const newSelectedFilters = { ...selectedFilters }
    if (newSelectedFilters[i]) {
      newSelectedFilters[i] = !newSelectedFilters
    } else {
      newSelectedFilters[i] = true
    }
    setSelectedFilters(newSelectedFilters)
    onChange(newSelectedFilters)
  }

  return (
    <ChipFiltersStyle className={className}>
      <Row flexWrap="nowrap" columnGap="5px">
        {
          filters.map((filter, index) => {
            const isFilterSelected = selectedFilters[index]
            if(isFilterSelected){
              return (
                <Chip
                  color="primary"
                  label={filter.label}
                  onDelete={() => handleChipClick(index)}
                  key = { filter.label }
                />
              )
            }else{
              return null
            }
          })
        }
        {
          filters.map((filter, index) => {
            const isFilterSelected = selectedFilters[index]
            if(!isFilterSelected){
              const chipProps = {
                color: undefined,
                label: filter.label,
                onClick: () => handleChipClick(index),
                key: filter.label
              }
              return (
                <Chip
                  {...chipProps}
                />
              )
            }else{
              return null
            }
          })
        }
      </Row>
    </ChipFiltersStyle>
  )
}

export default ChipFilters