import { Navigate } from 'react-router-dom'

const RestrictedRoute = ({ Element, isLoggedIn, ...props }) => {
  return (
    <>
      {
        isLoggedIn ?
          <Element {...props}/>
          :
          <Navigate
            to='/login'
          />
      }
    </>
  )
}

export default RestrictedRoute
