import React, { useCallback, useContext, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { LoginStyle } from './index.style'
import ShowSnackbar from '../../components/ShowSnackbar';
import { isEmpty } from 'lodash';
import { parseError } from '../../helpers/utility';
import LoginContext from '../../contexts/login/context';
import APIContext from '../../contexts/apis/context';
import { useNavigate } from 'react-router-dom';
import CustomText from '../../components/CustomText';

function Login() {
  const { login, loginData, resetState,
    resetLoaders
  } = useContext(APIContext)
  const { handleUpdateLoginState } = useContext(LoginContext)

  const navigate = useNavigate()

  useEffect(() => {
    if (loginData && loginData.status === 'failed') {
      if(loginData?.rawError?.isUser === false){
        navigate('/signup', { replace: true })
      }else{
        ShowSnackbar.error({
          text: loginData.error,
          visible: true
        })
      }
      resetState('loginData')
    } else if (loginData && loginData.status === 'success') {
      navigate('/', { replace: true })
      handleUpdateLoginState(true)
      resetState('loginData')
    }
  }, [loginData, resetState])

  useEffect(()=>{
    return ()=>{
      //resetting all loaders as when the page is visited again, the loader by default is false
      resetLoaders(['loginData_l'])
    }
  },[])

  const handleSubmit = useCallback((event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = data.get('username')
    const password = data.get('password')
    const tempError = {}
    if (!username?.trim()) {
      tempError.username = 'Invalid Username'
    }
    if (!password) {
      tempError.password = 'Invalid Password'
    }

    if (!isEmpty(tempError)) {
      ShowSnackbar.error({
        text: parseError(tempError),
        visible: true
      })
      return
    }
    login({ username, password })
  }, [])

  const handleSignup = ()=>{
    navigate('/signup')
  }

  return (
    <LoginStyle>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            type="text"
            fullWidth
            id="mobile"
            label="Company Mobile"
            name="username"
            autoComplete="mobile"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Continue
          </Button>
          <CustomText
            fontSize="s3"
          >
            Don't have an account?
          </CustomText>
          <Button sx={{ padding: 0 }} variant="text" onClick={handleSignup}>
            Signup Here
          </Button>
        </Box>
      </Box>
    </LoginStyle>
  );
}
export default Login