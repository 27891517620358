import { useTheme } from "styled-components";
import { getDiscountPercent } from "../../helpers/utility";
import CustomText from "../CustomText";
import { getSizeBelow } from "../CustomText/index.style";
import { DiscountedMRPStyle } from "./index.style";

function DiscountedMRP(props) {
  const { MRP, SP, sizeLabel = "s3"} = props
  const theme = useTheme()

  return (
    <DiscountedMRPStyle align="center">
      <CustomText
        fontSize={sizeLabel}
        isRupee
        fontWeight={500}
      >
        {SP}
      </CustomText>
      <CustomText
        fontSize={getSizeBelow(sizeLabel,2)}
        isRupee
        textDecoration="line-through"
        padLeft="5px"
      >
        {MRP}
      </CustomText>
      <CustomText
        fontSize={getSizeBelow(sizeLabel,2)}
        padLeft="5px"
        fontColor={theme.color.yellow.main}
      >
        ({getDiscountPercent(MRP,SP)}% OFF)
      </CustomText>
    </DiscountedMRPStyle>
  )
}

export default DiscountedMRP