import { useContext, useEffect } from "react"
import { Route, Routes } from "react-router-dom"
import AddProduct from "../containers/AddProduct"
import LandingPage from "../containers/LandingPage"
import Login from "../containers/Login"
import Orders from "../containers/Orders"
import OrderDetail from "../containers/OrderDetail"
import Products from "../containers/Products"
import LoginContext from "../contexts/login/context"
import RestrictedRoute from "./RestrictedRoute"
import ProductDetail from "../containers/ProductDetail"
import AddNewProduct from "../containers/AddNewProduct"
import AddNewVariant from "../containers/AddNewVariant"
import ProductReqList from "../containers/ProductReqList"
import Signup from "../containers/Signup"
import PrivacyPolicy from "../containers/PrivacyPolicy"
import DeleteAccount from "../containers/DeleteAccount"

function RoutesContainer() {
  const {isLoggedIn} = useContext(LoginContext)
  
  useEffect(() => {
    document.getElementById('bundleDownloadScreen').style.display = "none"
  }, [])

  return (
    <Routes>
      <Route exact path="/login" element={
        <Login />
      }
      />
      <Route exact path="/signup" element={
        <Signup />
      }
      />
      <Route exact path="/privacy-policy" element={
        <PrivacyPolicy />
      }
      />
      <Route exact path="/delete-account" element={
        <DeleteAccount />
      }
      />
      <Route exact path="/add-new-variant/:productReqId" element={
        <RestrictedRoute isLoggedIn={isLoggedIn} Element={AddNewVariant} />
      }
      />
      <Route exact path="/add-new-product" element={
        <RestrictedRoute isLoggedIn={isLoggedIn} Element={AddNewProduct} />
      }
      />
      <Route exact path='/add-product' element={
        <RestrictedRoute isLoggedIn={isLoggedIn} Element={AddProduct} />
      }
      />
      <Route exact path="/orders" element={
        <RestrictedRoute isLoggedIn={isLoggedIn} Element={Orders} />
      }
      />
      <Route exact path="/order-detail/:orderProductId" element={
        <RestrictedRoute isLoggedIn={isLoggedIn}  Element={OrderDetail} />
      }
      />
      <Route exact path="/product/:productId" element={
        <RestrictedRoute isLoggedIn={isLoggedIn}  Element={ProductDetail} />
      }
      />
      <Route exact path="/products" element={
        <RestrictedRoute isLoggedIn={isLoggedIn} Element={Products} />
      }
      />
      <Route exact path="/product-req-list" element={
        <RestrictedRoute isLoggedIn={isLoggedIn} Element={ProductReqList} />
      }
      />
      <Route exact path="/" element={
        <RestrictedRoute isLoggedIn={isLoggedIn} Element={LandingPage} />
      }
      />
    </Routes>
  )
}

export default RoutesContainer