import { Paper } from "@mui/material"
import { isEmpty } from "lodash"
import moment from "moment"
import { useContext, useEffect, useMemo } from "react"
import { useParams } from "react-router-dom"
import { useTheme } from "styled-components"
import { Divider, Row } from "../../commonStyles/index.style"
import CustomText from "../../components/CustomText"
import ImageHorizontalCard from "../../components/ImageHorizontalCard"
import Layout from "../../components/layouts/Layout"
import ShowSnackbar from "../../components/ShowSnackbar"
import APIContext from "../../contexts/apis/context"
import { getOrderStatus } from "../../helpers/utility"
import { OrderDetailStyle } from "./index.style"

function OrderDetail(props) {
  const { orderProductId } = useParams()
  const { getOrderDetail, getOrderDetailData, getOrderDetailData_l, resetState, resetLoaders } = useContext(APIContext)

  useEffect(() => {
    getOrderDetail(orderProductId)
  }, [])
  
  useEffect(()=>{
    return ()=>{
      //resetting all loaders as when the page is visited again, the loader by default is false
      resetLoaders(['getOrderDetailData'])
    }
  },[])

  useEffect(() => {
    if (getOrderDetailData && getOrderDetailData.status === 'failed') {
      ShowSnackbar.error({
        text: getOrderDetailData.error,
        visible: true
      })
      resetState('getOrderDetailData')
    }
  }, [getOrderDetailData])

  const item = useMemo(() => {
    const orderData = getOrderDetailData?.data?.data
    return {
      address: orderData?.addressDetail || {},
      orderDetail: orderData?.orderProductDetail || {},
      productDetail: orderData?.productDetail || {},
      processedOrderedProductStatus: orderData?.processedOrderedProduct?.orderProductStatus,
      variantDetail: orderData?.variantDetail || {},
      imgUrl: !isEmpty(orderData?.variantDetail?.imgUrl) ? orderData?.variantDetail?.imgUrl : orderData?.productDetail?.imgUrl
    }
  }, [getOrderDetailData])

  const theme = useTheme()
  const orderStatus = getOrderStatus(item.processedOrderedProductStatus)

  return (
    <Layout title="Order Detail" isLoading={!getOrderDetailData_l}>
      <OrderDetailStyle>
        <Paper sx={{ padding: '10px',marginBottom: '20px' }} variant="outlined"  square>
          <CustomText
            fontColor={theme.color.dark}
            fontSize="m1"
            marginBottom="0"
          >
            Delivery Address
          </CustomText>
          <Divider />
          <CustomText
            fontColor={theme.color.black}
            fontSize="m1"
            fontWeight="600"
          >
            {item.address?.name}
          </CustomText>
          <CustomText
            fontColor={theme.color.dark}
            fontSize="m1"
          >
            {item.address?.locality}, {item.address?.address}, {item.address?.city}, {item.address?.pincode}, {item.address?.state}
          </CustomText>
          <CustomText
            fontColor={theme.color.black}
            fontSize="m1"
            fontWeight="600"
            paddingTop="8px"
          >
            Phone No
          </CustomText>
          <CustomText
            fontColor={theme.color.dark}
            fontSize="m1"
          >
            {item.address?.phone}
          </CustomText>
        </Paper>
        <Paper sx={{ padding: '10px',marginBottom: '20px' }} variant="outlined"  square>
          <CustomText
            fontColor={theme.color.dark}
            fontSize="m1"
          >
            Order Date: {moment(item.orderDetail?.addedAt).format('DD MMMM YYYY')}
          </CustomText>
          <Divider />
          <ImageHorizontalCard imgUrl={item?.imgUrl?.[0]} imgLinkUrl={`/product/${item.productDetail?.productId}`} height="100px">
            <Row justify="space-between" flexGrow={1}>
              <div>
                <CustomText
                  fontColor={theme.color.dark}
                  fontSize="m1"
                >
                  {item?.productDetail?.productName}
                </CustomText>
                <CustomText
                  fontColor={theme.color.secondary}
                  fontSize="s3"
                >
                  ({item.variantDetail?.variantName})
                </CustomText>
                <CustomText
                  fontColor={theme.color.secondary}
                  fontSize="s3"
                >
                  {item?.brand?.brandName}
                </CustomText>
                {
                  item.orderDetail?.quantity && 
                  <CustomText
                    fontColor={theme.color.secondary}
                    fontSize="s3"
                  >
                    Quantity: {item.orderDetail?.quantity}
                  </CustomText>
                }
              </div>
              <CustomText
                isRupee
                fontColor={theme.color.black}
                fontSize="m1"
              >
                {item.orderDetail?.SP}
              </CustomText>
            </Row>
          </ImageHorizontalCard>
          <Divider />
          <Row justify="space-between">
            <Row flexDirection="column" align="center">
              <CustomText
                fontColor={theme.color.black}
                fontSize="m1"
              >
                OrderId
              </CustomText>
              <CustomText
                fontColor={theme.color.black}
                fontSize="m1"
              >
                {item.orderDetail?.orderId}
              </CustomText>
            </Row>
            <Row flexDirection="column" align="center">
              <CustomText
                fontColor={theme.color.black}
                fontSize="m1"
              >
                Delivery Status
              </CustomText>
              <CustomText
                fontColor={orderStatus?.color}
                fontSize="m1"
              >
                {orderStatus?.label}
              </CustomText>
            </Row>
          </Row>
        </Paper>
      </OrderDetailStyle>
    </Layout>
  )
}

export default OrderDetail