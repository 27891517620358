import styled from "styled-components";

export const LandingPageStyle = styled.div`
  max-width: 100%;
  padding-bottom: 10px;
  overflow-x: hidden;
  .link:hover{
    text-decoration: none;
  }
  .section-top {
    min-height: 40vh;
    position: relative;
    overflow: hidden;
    z-index:0;
  }
  
  .section-top::before {
    z-index:-1;
    border-radius: 100%;
    position: absolute;
    background-color: ${props=>props.theme.color.primary};
    right: -200px;
    left: -200px;
    top: -200px;
    content: '';
    bottom: 0;
  }
  .section-middle{
    padding: 10px;
    min-height: 260px;
  }
  .sliding-orders{
    margin-top: 5px;
    overflow-x: auto;
    padding: 10px;
  }
  .section-last{
    position: absolute;
    width: 100%;
    bottom: 10px;
    padding: 10px;
  }
`