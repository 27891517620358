import styled from 'styled-components'

const getMobileSize = (fs)=>{
  switch (fs) {
    case 'hMax':
      return '50px'
    case 'h3':
      return '26px'
    case 'h2':
      return '24px'
    case 'h1':
      return '22px'
    case 'm3':
      return '20px'
    case 'm2':
      return '18px'
    case 'm1':
      return '16px'
    case 's3': 
      return '14px'
    case 's2':
      return '12px'
    case 's1':
      return '10px'
    default: return fs
  }
}

const getTabletSize = (fs)=>{
  switch (fs) {
    case 'h3':
      return '28px'
    case 'h2':
      return '26px'
    case 'h1':
      return '24px'
    case 'm3':
      return '22px'
    case 'm2':
      return '20px'
    case 'm1':
      return '18px'
    case 's3': 
      return '16px'
    case 's2':
      return '14px'
    case 's1':
      return '13px'
    default: return fs
  }
}

const getSize = (fs) => {
  switch (fs) {
    case 'h3':
      return '30px'
    case 'h2':
      return '28px'
    case 'h1':
      return '26px'
    case 'm3':
      return '24px'
    case 'm2':
      return '22px'
    case 'm1':
      return '20px'
    case 's3':
      return '18px'
    case 's2': 
      return '16px'
    case 's1':
      return '14px'
    default: return fs
  }
}

export const getSizeBelow = (size,n=1)=>{
  //this function takes the size label and returns size smaller by n 
  const sizeLabel = ['s1','s2','s3','m1','m2','m3','h1','h2','h3']
  const sizeCharacterPayload = {
    s: -1,
    m: 2,
    h: 5
  }

  const indexOfSize = parseInt(size.charAt(1)) + sizeCharacterPayload[size.charAt(0)]

  return sizeLabel[indexOfSize-n]
}

export const CustomTextStyle = styled.div`
  color: ${props=>props.fontColor || 'inherit'};
  font-size: ${props => getMobileSize(props.fontSize) || '12px'};
  font-weight: ${props => props.fontWeight || 'normal'};
  text-align: ${props => props.textAlign || 'left'};
  padding-top: ${props => props.paddingTop || '0px'};
  padding-left: ${props => props.padLeft || '0px'};
  padding-right: ${props => props.padRight || '0px'};
  margin-bottom: ${props => props.marginBottom || '2px'};
  line-height: ${props => props.lineHeight || 'inherit'};
  background-color: ${props => props.bgColor || 'inherit'};
  font-family: ${props => props.fontFamily || 'inherit'};
  text-decoration: ${props=>props.textDecoration || 'none'};

  @media (min-width: 992px){
    font-size: ${props => getTabletSize(props.fontSize) || '12px'};
  }

  @media (min-width: 1200px){
    font-size: ${props => getSize(props.fontSize) || '14px'};
  }
`