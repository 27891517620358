import styled from "styled-components";
import { colorPallet } from "../../commonStyles/colors";

export const LoaderStyle = styled.div`
  top: 10vh;
  min-height: 80vh;
  width: 100vw;
  background-color: ${colorPallet.white};
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  z-index:10;
`