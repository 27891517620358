import { useCallback, useState } from 'react'
import { FormStyle } from './index.style'
import FormItem from './components/FormItem'
import { useEffect } from 'react';
import { isZeroOrValid } from '../../utils/textValidatorUtils';
import { isEmpty, find } from 'lodash';
import { Grid } from '@mui/material';

export default function Form(props) {
  const [errors, setErrors] = useState({})
  const [values, setValues] = useState({})
  const {getSubmitMethod, formData} = props

  const resetForm = useCallback(()=>{
    setValues({})
  },[])

  const handleSubmit = useCallback((e) => {
    e.preventDefault()
    let errorObj = {}
    formData.forEach((item)=>{
      if(item.visible !== false){
        if(item.required && !isZeroOrValid(values[item.name])){
          errorObj[item.name] = `${item.name} cannot be blank`
        }
  
        if(isZeroOrValid(values[item.name])){
          if(item.type === 'text'){
            if(isZeroOrValid(item.min) && !errorObj[item.name] && values[item.name].length < item.min){
              errorObj[item.name] = `Should be greater than ${item.min}`
            }
            if(isZeroOrValid(item.max) && !errorObj[item.name] && values[item.name].length > item.max ){
              errorObj[item.name] = `Should be less than ${item.max}`
            }
          }
          if(item.regex && !item.regex.test(values[item.name])){
            errorObj[item.name] = item.regexError || 'Invalid value'
          }
        }
      }
    })
    if(isEmpty(errorObj)){
      return {...values}
    }else{
      setErrors({...errorObj})
    }
  }, [values, formData])

  //here i am parcelling the submit method to parent component for more control
  useEffect(()=>{
    getSubmitMethod(handleSubmit, values, resetForm)
  },[getSubmitMethod, handleSubmit, values])

  useEffect(()=>{
    const defaultValues = {} 
    formData.forEach((item)=>{
      if(isZeroOrValid(item.defaultValue)){
        defaultValues[item.name] = item.defaultValue
      }
    })
    setValues({...defaultValues})
  },[])

  const handleOnChange = useCallback((name, value) => {
    const formItem=find(formData,{name:name})
    if(formItem.onChange){
      formItem.onChange(name,value)
    }
    setValues({
      ...values,
      [name]: value
    })
  },[values, formData])

  return (
    <FormStyle>
      <form>
        <Grid container spacing={3}>
          {
            formData.filter(it=>{
              return !(it.visible===false)
            }).map((item) => {
              return (
                <Grid item xs={12} md={item.width || 3}
                  key={item.name}
                >
                  <FormItem
                    type={item.type}
                    name={item.name}
                    variant={item.variant}
                    required={item.required}
                    placeholder={item.placeholder}
                    label={item.label}
                    error={errors[item.name]}
                    onChange={handleOnChange}
                    desc={item.desc}
                    value={values[item.name]}
                    accept={item.accept}
                    multiple={item.multiple}
                    regex={item.regex}
                    regexError={item.regexError}
                    min={item.min}
                    max={item.max}
                    currency={item.currency}
                    options={item.options}
                    optionLabelKey={item.optionLabelKey}
                    multiline={item.multiline}
                    rows={item.rows}
                    keyName={item.keyName}
                    valueName={item.valueName}
                    inputProps={item.inputProps}
                  />
                </Grid>
              )
            })
          }
        </Grid>
      </form>
    </FormStyle>
  )
}