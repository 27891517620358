import { Alert, Avatar, Button } from "@mui/material"
import { DeleteAccountStyle } from "./index.style"
import { useContext, useRef } from "react"
import APIContext from "../../contexts/apis/context"
import ShowSnackbar from "../../components/ShowSnackbar"
import { useCallback } from "react"
import { useEffect } from "react"
import Form from "../../components/Form"
import { isMobileScreen } from "../../helpers/utility"
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

function DeleteAccount() {
    const submitFormRef = useRef('')
    const resetFormRef = useRef('')

    const {
        deleteAccountRequest, deleteAccountRequestData, deleteAccountRequestData_l,
        resetState, resetLoaders
    } = useContext(APIContext)

    const handleSubmit = useCallback(async (e) => {
        const response = submitFormRef.current(e)
        if (response) {
            deleteAccountRequest(response)
            resetFormRef.current()
        }
    }, [])


    const getSubmitMethod = useCallback((submitMethod, values, resetForm) => {
        submitFormRef.current = submitMethod
        resetFormRef.current = resetForm
    }, [])

    useEffect(() => {
        if (deleteAccountRequestData && deleteAccountRequestData.status === 'success') {
            ShowSnackbar.success({
                text: "Account Deletion request submitted successfully",
                visible: true
            })
            resetState('deleteAccountRequestData')
        }
        else if (deleteAccountRequestData && deleteAccountRequestData.status === 'failed') {
            ShowSnackbar.error({
                text: deleteAccountRequestData.error,
                visible: true
            })
            resetState('deleteAccountRequestData')
        }
    }, [deleteAccountRequestData])

    useEffect(() => {
        return () => {
            //resetting all loaders as when the page is visited again, the loader by default is false
            resetLoaders(['deleteAccountRequestData'])
        }
    }, [])

    const formData = deleteAccountRequestForm({})

    return (
        <DeleteAccountStyle isMobileScreen={isMobileScreen()}>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main', margin: 'auto', marginBottom: '20px' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Alert sx={{ marginBottom: '20px' }} severity="warning">
                Please enter your mobile number below to request for account deletion
            </Alert>
            <Form
                formData={formData}
                getSubmitMethod={getSubmitMethod}
            />
            <Button fullWidth sx={{ marginBottom: '20px', marginTop: "30px" }}
                size="large"
                variant="contained"
                onClick={handleSubmit}
            >
                Submit
            </Button>
        </DeleteAccountStyle>
    )
}

export default DeleteAccount


const deleteAccountRequestForm = () => {
    return [
        {
            label: 'Enter mobile',
            name: 'mobile',
            type: 'text',
            width: 12,
            placeholder: '',
            desc: '',
            regex: /^\d{10,12}$/,
            regexError: 'Invalid mobile number',
            required: true,
            variant: 'outlined'
        }
    ]
}