import { useEffect, useCallback, useRef } from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner} from '@fortawesome/free-solid-svg-icons'


//TODO: is not working Properly for some reason so not using it 
function InfiniteScroll(props){
  //we are using this refs because the functions are being called from the callback of the Intersection Observer and it's not able to retain the value in case of funciton component
  const prevY = useRef(0)
  const observerRef = useRef('')
  const {isNoMoreData=false} = props
  const {isLoading,rootMargin} = props
  const childrenRef = useRef()

  const observerFunction = useCallback((entries)=>{
    const y = entries[0].boundingClientRect.y
    if(prevY.current > y && childrenRef.current.length != 0){
      props.OnEndReach()
    }
    prevY.current=y
  },[prevY,props])

  useEffect(()=>{
    childrenRef.current = props.children
  },[props.children])

  const createObserver = useCallback(()=>{
    const options = {
      root: null,
      threshold: 1,
      rootMargin: rootMargin? rootMargin:'0px'
    }
    if(!observerRef.current){
      observerRef.current = new IntersectionObserver(observerFunction,options)
      observerRef.current.observe(document.getElementById('tracker'))
    }
  },[observerFunction])

  useEffect(()=>{
    createObserver()
  }, [createObserver])

  useEffect(()=>{
    //stopping observation on unmount
    return ()=>{
      if(observerRef.current){
        observerRef.current.disconnect()
      }
    }
  },[])

  useEffect(()=>{
    //stopping observation if no more data is available
    if(isNoMoreData){
      observerRef.current.disconnect()
      observerRef.current=''
    }else{
      createObserver()
    }
  },[isNoMoreData])

  return (
    <div>
      {props.children}
      {
        <div id="tracker" style={{height:'5vh'}}></div>
      }
      {
        isLoading &&
        <div className="text-center"> 
          <FontAwesomeIcon
            className="fa-spin"
            icon={faSpinner}
            size="lg"
          /> 
        </div>
      }
    </div>
  )
}


export default InfiniteScroll