import styled from "styled-components";

export const AddNewVariantStyle = styled.div`
  max-width: 100%;
  padding: 10px;
  .add-icon{
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
`
export const VariantDataModalStyle = styled.div`
  padding: 20px;
  background-color: ${props=>props.theme.color.white};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
`