import { useState, useEffect, useRef, useCallback } from 'react'
import { colorPallet } from '../../commonStyles/colors'
import { sizes } from '../../commonStyles/fontSizes'
import { Row } from '../../commonStyles/index.style'
import CustomText from '../CustomText'
import { ShowSnackbarStyle } from './index.style'

function ShowSnackbar (props) {
  const { show, text, error } = props
  const [state, setState] = useState({
    text,
    error,
    visible: show
  })
  const timerRef = useRef(null)

  ShowSnackbar.success = useCallback(({ text, visible }) => {
    setState({
      visible,
      text
    })
  }, [])

  ShowSnackbar.error = useCallback(({ text, visible }) => {
    setState({
      visible,
      text,
      error: true
    })
  }, [])

  useEffect(() => {
    // setSnackBar(show)
    if (state.visible) {
      timerRef.current = setTimeout(() => {
        setState({
          visible: false,
          text: '',
          error: false
        })
        clearTimeout(timerRef.current)
      }, 5000)
    }
  }, [state.visible])

  return state.visible ? (
    <ShowSnackbarStyle visible={state.visible} error={state.error}>
      <Row align='center' justify='space-between' className='snack-bar-box'>
        <CustomText fontColor={colorPallet.white} fontSize="s3">
          {state.text}
        </CustomText>
      </Row>
    </ShowSnackbarStyle>
  ) : null
}

export default ShowSnackbar
