import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { forwardRef, useCallback } from 'react';
import { useTheme } from 'styled-components';
import CustomText from '../CustomText';
import { Row } from '../../commonStyles/index.style';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function FullScreenDialog(props) {
  const { open, title = "modal", handleDone, handleDialogeToggle } = props

  const theme = useTheme()

  const handleClose = useCallback(() => {
    handleDialogeToggle()
  }, [handleDialogeToggle])

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <Row justify="space-between" width="100%" align="center">
            <Row align="center">
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
              {
                title &&
                <CustomText
                  paddingLeft="10px"
                  fontSize="m2"
                  marginBottom="0px"
                  fontColor={theme.color.white}
                  fontWeight={600}
                >
                  {title}
                </CustomText>
              }
            </Row>
            <Button 
              size="large" 
              color="inherit" 
              onClick={handleDone}
              sx={{fontSize: '20px'}}
            >
              SAVE
            </Button>
          </Row>
        </Toolbar>
      </AppBar>
      {
        props.children
      }
    </Dialog>
  )
}

export default FullScreenDialog