import styled from "styled-components";

export const VariantsListStyle = styled.div`
  .item-cards-container{
    padding-top: 20px;
  }
  .item-card{
    margin-bottom: 15px;
  }
  .section-add-btn{
    position: absolute;
    bottom: 0px;
    width: 95%;
    padding: 10px;
  }
  padding-bottom: 10vh;
`

export const VariantModalStyle = styled.div`
  padding: 20px;
  background-color: ${props=>props.theme.color.white};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
`