import moment from 'moment'
import { ProductReqListStyle } from './index.style'
import { useContext, useRef, useState } from 'react'
import APIContext from '../../contexts/apis/context'
import { useEffect } from 'react'
import ShowSnackbar from '../../components/ShowSnackbar'
import { useCallback } from 'react'
import { Avatar, CircularProgress, InputAdornment, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, TextField } from '@mui/material'
import Layout from '../../components/layouts/Layout'
import ChipFilters from '../../components/ChipFilters'
import CustomText from '../../components/CustomText'
import { Row } from '../../commonStyles/index.style'
import { useTheme } from 'styled-components'
import { getProductReqStatus, PRODUCT_REQ_STATUS } from '../../helpers/utility'
import InfiniteScroll from '../../components/InfiniteScroll'
import { useNavigate } from 'react-router-dom'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const ORDER_FILTERS_ARRAY = Object.keys(PRODUCT_REQ_STATUS).map((o) => (getProductReqStatus(o)))

function ProductReqList() {
  const { getProductReqList, getProductReqListData, getProductReqListData_l, resetState, resetLoaders } = useContext(APIContext)
  const [isNoMoreData, setIsNoMoreData] = useState(false)
  const [productReqList, setProductReqList] = useState([])
  const pageSize = useRef(10)
  const pageNumber = useRef(0)
  const statusFilters = useRef([])
  const navigate = useNavigate()

  const theme = useTheme()

  const handleGetProductReqList = useCallback(() => {
    getProductReqList({
      pageNumber: pageNumber.current,
      pageSize: pageSize.current,
      status: statusFilters.current
    })
    pageNumber.current = pageNumber.current + 1
  }, [pageNumber.current, pageSize.current, statusFilters.current])

  useEffect(()=>{
    handleGetProductReqList()
    return ()=>{
      //resetting all loaders as when the page is visited again, the loader by default is false
      resetLoaders(['getProductReqListData'])
    }
  },[])

  useEffect(() => {
    if (getProductReqListData && getProductReqListData.status === 'success') {
      setProductReqList([
        ...productReqList,
        ...getProductReqListData?.data?.data
      ])
      if (getProductReqListData?.data?.data?.length === 0) {
        setIsNoMoreData(true)
      }
      resetState('getProductReqListData')
    }
    else if (getProductReqListData && getProductReqListData.status === 'failed') {
      ShowSnackbar.error({
        text: getProductReqListData.error,
        visible: true
      })
      resetState('getProductReqListData')
    }
  }, [getProductReqListData, productReqList])

  //commenting search code as it's low priority and can be picked up later
  // const handleChange = useCallback((val) => {
  //   if (val) {
  //   }
  // }, [handleGetProductReqList])

  const handleFiltersChange = useCallback((filters) => {
    setIsNoMoreData(false)
    const fArray = []
    Object.keys(filters).forEach((f) => {
      if (filters[f]) {
        fArray.push(ORDER_FILTERS_ARRAY[f].key)
      }
    })
    statusFilters.current = fArray
    //resetting pageNumber whenever filter changes
    pageNumber.current = 0
    setProductReqList([])
    handleGetProductReqList()
  }, [])

  const handleListClick = useCallback((l) => {
    navigate(`/add-new-variant/${l.productReqId}`)
  }, [])

  const handleOnEndReach = useCallback(() => {
    handleGetProductReqList()
  }, [pageNumber, handleGetProductReqList])


  return (
    <Layout title="Product Requests">
      <ProductReqListStyle>
        {/* <TextField
          label="Search"
          onChange={handleChange}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {!getProductReqListData_l ? <CircularProgress color="inherit" size={20} /> : null}
              </>
            ),
          }}
        /> */}
        <ChipFilters className="filters" filters={ORDER_FILTERS_ARRAY} onChange={handleFiltersChange} />
        <div className="items-list">
          <List sx={{ padding: 0 }}>
            <InfiniteScroll OnEndReach={handleOnEndReach} isNoMoreData={isNoMoreData} rootMargin="10px">
              {
                productReqList?.map((op, i) => {
                  const item = op
                  const productReqStatus = getProductReqStatus(op.status)
                  return (
                    <ListItem key={op.productReqId} sx={{ padding: 0 }}>
                      <ListItemButton sx={{ paddingLeft: 0, paddingRight: 0 }}
                        role={undefined}
                        onClick={() => handleListClick(op, i)}
                        dense
                      >
                        <ListItemAvatar>
                          <Avatar alt={item?.productName} src={item.imgUrl[0]} />
                        </ListItemAvatar>
                        <ListItemText primary={
                          <CustomText
                            fontSize="s3"
                            overflowLength={50}
                          >
                            {item.productName}
                          </CustomText>
                        }
                          secondary={
                            <div>
                              <CustomText>
                                {moment(op.addedAt).format('MMMM Do YYYY HH:mm:ss')}
                              </CustomText>
                              <CustomText
                                fontColor={productReqStatus.color}
                              >
                                {productReqStatus.label}
                              </CustomText>
                            </div>
                          }
                        />
                        <ChevronRightIcon/>
                      </ListItemButton>
                    </ListItem>
                  )
                })
              }
            </InfiniteScroll>
          </List>
          {
            !getProductReqListData_l &&
            <Row justify="center" className="list-loader-container" align="center">
              <CircularProgress color="inherit" />
            </Row>
          }
        </div>
      </ProductReqListStyle>
    </Layout>
  )
}

export default ProductReqList