export const colorPallet = {
  primary: '#1554f6',
  white: '#ffffff',
  borderGrey: '#dee2e6',
  dark: '#212121',
  secondary: '#786452',
  lightGrey: '#f1f3f6',
  black: '#000000',
  red: {
    light: '#ef5350',
    main: '#d32f2f',
    dark: '#c62828'
  },
  yellow: {
    light: '#ff9800',
    main: '#ed6c02',
    dark: '#e65100'
  },
  blue: {
    light: '#03a9f4',
    main: '#0288d1',
    dark: '#01579b'
  },
  green:{
    light: '#4caf50',
    main: '#2e7d32',
    dark: '#1b5e20'
  }
}

