import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import CustomText from '../CustomText';
import { useTheme } from 'styled-components';

function CircularProgressWithLabel(props) {
  const theme = useTheme()
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress size={100} variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CustomText
          fontColor={theme.color.dark}
          fontSize="s3"
        >
          {`${Math.round(props.value)}%`}
        </CustomText>
        
      </Box>
    </Box>
  );
}

export default CircularProgressWithLabel