import { Avatar, Button } from "@mui/material"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { useTheme } from "styled-components"
import { Row } from "../../commonStyles/index.style"
import APIContext from "../../contexts/apis/context"
import { getDiscountPercent, getVariantStatus } from "../../helpers/utility"
import { isZeroOrValid } from "../../utils/textValidatorUtils"
import CustomText from "../CustomText"
import DiscountedMRP from "../DiscountedMRP"
import Form from "../Form"
import ShowSnackbar from "../ShowSnackbar"
import { SupplierVariantFormStyle } from "./index.style"

const USE_CASE = {
  ADD: 'ADD',
  UPDATE: 'UPDATE'
}

function SupplierVariantForm(props) {
  const {useCase = USE_CASE.UPDATE, defaultData={}, supplierVariantId, variantId, onSubmit, handleCallback} = props
  
  const { updateSupplierVariant, updateSupplierVariantData, updateSupplierVariantData_l, 
    resetState 
  } = useContext(APIContext)

  const [updatedVariantData,setUpdatedVariantData] = useState({...defaultData})

  const submitFormRef = useRef('')  
  const theme = useTheme()

  useEffect(() => {
    if (updateSupplierVariantData && updateSupplierVariantData.status === 'success') {
      if(handleCallback) handleCallback('success')
      ShowSnackbar.success({
        text: 'Detail Saved Successfully',
        visible: true
      })
      resetState('updateSupplierVariantData')
    }
    else if (updateSupplierVariantData && updateSupplierVariantData.status === 'failed') {
      if(handleCallback) handleCallback('error')
      ShowSnackbar.error({
        text: updateSupplierVariantData.error,
        visible: true
      })
      resetState('updateSupplierVariantData')
    }
  }, [updateSupplierVariantData, handleCallback])
  
  const handleSubmit = useCallback(async (e)=>{
    const response = submitFormRef.current(e)
    if(response){
      if(useCase === USE_CASE.ADD){
        handleCallback('success', {
          ...defaultData,
          MRP: response.MRP,
          SP: response.SP,
          stock: response.stock,
          variantId: variantId
        })
      }else{
        updateSupplierVariant({
          MRP: response.MRP,
          SP: response.SP,
          stock: response.stock,
          supplierVariantId: supplierVariantId
        })
      }
    }
  },[updateSupplierVariant, onSubmit, useCase, 
    variantId, supplierVariantId, defaultData
  ])

  const handleVariantDataChange = useCallback((name,value)=>{
    const variantChangedData = {...updatedVariantData}

    switch(name){
      case 'MRP':
        variantChangedData.MRP = value
        variantChangedData.discountPercent = getDiscountPercent(value,updatedVariantData.SP)
        break        
      case 'SP':
        variantChangedData.SP = value
        variantChangedData.discountPercent = getDiscountPercent(updatedVariantData.MRP,value)
        break
    }
    setUpdatedVariantData({
      ...variantChangedData
    })  
  },[updatedVariantData])

  const getSubmitMethod = useCallback((submitMethod)=>{
    submitFormRef.current = submitMethod
  },[])

  const formData = getVariantForm(updatedVariantData,handleVariantDataChange)
  const variantStatus = getVariantStatus(updatedVariantData?.status) 
  
  return (
    <SupplierVariantFormStyle>
      <Row justify="center">
        <Avatar
          alt={updatedVariantData?.variantName}
          src={updatedVariantData?.imgUrl?.[0] ? updatedVariantData?.imgUrl?.[0] : {}}
          sx={{ height: 50, width: 50 }}
        />
      </Row>
      <CustomText
        fontColor={theme.color.primary}
        fontSize="m2"
        textAlign="center"
        paddingTop="8px"
      >
        {updatedVariantData?.variantName}
      </CustomText>
      {
        variantStatus && 
        <CustomText
          fontColor={ variantStatus?.color}
          fontSize="s3"
          textAlign="center"
          marginBottom="30px"
        >
          ( {variantStatus?.label} )
        </CustomText>
      }
      
      <Form
        formData={formData}
        getSubmitMethod={getSubmitMethod}
      />
      {
        isZeroOrValid(updatedVariantData?.MRP) && isZeroOrValid(updatedVariantData?.SP) &&
        <>
          <CustomText
            fontSize="m1"
            paddingTop="25px"
            textAlign="center"
            fontColor={theme.color.green.main}
            fontWeight={500}
            marginBottom="5px"
          >
            Final Price (After discount)
          </CustomText>
          <Row justify="center">
            <DiscountedMRP MRP={updatedVariantData?.MRP} SP={updatedVariantData?.SP} sizeLabel="m3" />
          </Row>
        </>
      }
      <Button fullWidth sx={{ marginBottom: '20px', marginTop: "30px" }}
        size="large"
        variant="contained"
        onClick={handleSubmit}
      >
        {
          useCase === USE_CASE.UPDATE?
          'Update'
          :
          'Add'
        }
      </Button>
    </SupplierVariantFormStyle>
  )
}

export default SupplierVariantForm

const getVariantForm = (data,handleVariantDataChange)=> {
  let variantForm =[
    {
      label: 'Enter MRP',
      name: 'MRP',
      type: 'amount',
      placeholder: '',
      desc:'',
      defaultValue: data?.MRP,
      onChange: handleVariantDataChange,
      min: 1,
      max: '',
      regex: '',
      required: true,
      currency: 'Rs',
      variant: 'standard'
    },
    {
      label: 'Enter Selling Price',
      name: 'SP',
      type: 'amount',
      placeholder: '',
      desc:'',
      defaultValue: data?.SP,
      onChange: handleVariantDataChange,
      min: 1,
      max: '',
      regex: '',
      required: true,
      currency: 'Rs',
      variant: 'standard'
    },
    {
      label: 'Enter Available Stock',
      name: 'stock',
      type: 'number',
      placeholder: '',
      desc:'',
      defaultValue: data?.stock,
      min: 1,
      max: '',
      regex: '',
      required: true,
      variant: 'standard'
    }
  ]
  return variantForm
}