import { isArray, isEmpty } from "lodash"

export const isPositiveNumber = (value)=>{
  return /^[0-9]{1,}$/.test(value)
}

export const isNegativeNumber = (value) =>{
  return /^-[0-9]{1,}$/.test(value)
}

export const isNumber = (value)=>{
  return /^-{0,1}[0-9]{1,}$/.test(value)
}

export const isZeroOrValid = (value)=>{
  if(value || value===0 || isArray(value)){
    if((typeof value === 'string') && value.trim()){
      return true
    }else if(isArray(value) && (value.length >0)){
      return true
    }else if(isNumber(value)){
      return true
    }else if(!isEmpty(value)){
      return true
    }else{
      return false
    }
  }else{
    return false
  }
}

export const isValidDate = (value)=>{
  
}