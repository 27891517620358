import { useCallback, useRef, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import defaultTheme from '../../commonStyles/defaultTheme';
import GlobalContext from './context'
import { createTheme, ThemeProvider as MaterialThemeProvider } from '@mui/material';
import { colorPallet } from '../../commonStyles/colors';

function GlobalProvider(props) {
  const [gstate, setGstate] = useState({})
  const [theme, setTheme] = useState({...defaultTheme})
  const materialUiTheme = useRef(createTheme({
    palette: {
      white: {main: colorPallet.white, contrastText: colorPallet.primary}
    }
  }))

  const handleSetgstate = useCallback((key,value=undefined)=>{
    setGstate((gs)=>(
      {
        ...gs,
        [key]: value
      }
    ))
  },[])

  const handleSetTheme = useCallback((suppliedTheme={})=>{
    //here we are setting theme for the website, giving priority to theme provided by supplier otherwise adding default theme
    setTheme({
      ...defaultTheme,
      ...suppliedTheme
    })
  },[])

  return (
    <GlobalContext.Provider
      value={{
        ...gstate, 
        handleSetgstate,
        handleSetTheme
      }}
    >
      {
        /*we are using two themes here one for the custome components built using styled-components, and another is material ui theme
         for material ui components
        */
      }
      <ThemeProvider theme={theme}>
        <MaterialThemeProvider theme={materialUiTheme.current}>
        {props.children}
        </MaterialThemeProvider>
      </ThemeProvider>
    </GlobalContext.Provider>
  )
}

export default GlobalProvider