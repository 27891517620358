import { useTheme } from "styled-components"
import { colorPallet } from "../../commonStyles/colors"
import { ButtonStyled } from "./index.style"

function Button(props) {
  const { onClick = undefined, styleType="", 
    className='', type="btn", 
    width='', padding='', margin='',
    color= colorPallet.darkBrown,
    hoverColor= colorPallet.darkerBrown,
    disabled, borderRadius= '0', 
    display="", ...rest
  } = props

  return (
    <ButtonStyled 
      display={display}
      borderRadius={borderRadius} 
      color={color} 
      hoverColor={hoverColor} 
      styleType={styleType} 
      width={width} 
      padding={padding} 
      margin={margin}
      {...rest}
    >
      <button 
        className={`button ${className} ${disabled?'disabled':''}`} 
        onClick={disabled? null : onClick} 
        type={type}
      >
        {props.children}
      </button>
    </ButtonStyled>
  )
}

export default Button