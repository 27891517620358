import { TextField, InputAdornment, Button, Typography, Box, MenuItem, Autocomplete } from '@mui/material'
import React, { useCallback, useState } from 'react'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { isPositiveNumber, isNumber, isZeroOrValid } from "../../../../utils/textValidatorUtils";
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import DatePicker from "../DatePicker";
import { colorPallet } from '../../../../commonStyles/colors';
import ShowSnackbar from '../../../ShowSnackbar';


//optionLabelKey - The label key(the value that will be shown for option) for the options passed in autocomplete 

export default function FormItem(props) {
  const { type, label, name, value = '', variant,
    required, error, onChange, desc, accept,
    multiple, min, max, currency, options = [],
    rows = 4, multiline = false, defaultValue, keyName = "name",
    valueName = "value", inputProps = {}, 
    optionLabelKey
  } = props

  let ele = ''
  const [fileUrls, setFileUrls] = useState([])

  const handleFileChange = useCallback((e) => {
    if (e.target.files.length > max) {
      ShowSnackbar.error({
        visible: true,
        text: `Cannot select more than ${max} images`
      })
      return
    }
    const fUrls = [...e.target.files].map((file) => {
      return {
        extension: file.type,
        url: URL.createObjectURL(file)
      }
    })
    setFileUrls([...fUrls])
    onChange(name, [...e.target.files])
  }, [setFileUrls, name, onChange])

  const handleRemoveFile = useCallback((i) => {
    const newFileUrls = fileUrls.filter((fUrl, index) => {
      return i !== index
    })
    setFileUrls([...newFileUrls])
    onChange(name, [...newFileUrls])
  }, [fileUrls, name, onChange])

  const handleChange = useCallback((val) => {
    if (isZeroOrValid(val)) {
      switch (type) {
        case 'mobile':
          if (!isPositiveNumber(val)) {
            return
          }
          if (val.length > 10) {
            return
          }
          break;
        case 'number':
        case 'amount':
          if (!isNumber(val)) {
            return
          }
          if (isZeroOrValid(min) && val < min) {
            return
          }
          if (isZeroOrValid(max) && val > max) {
            return
          }
          break;
        default:
          break;
      }
    }
    if (['number', 'amount'].includes(type)) {
      if (isNumber(val)) {
        onChange(name, Number(val))
      } else {
        onChange(name, val)
      }
    } else {
      onChange(name, val)
    }
  }, [name, onChange,
    min, max, type
  ])

  switch (type) {
    case 'password':
      ele = <TextField
        type={type}
        name={name}
        variant={variant}
        required={required}
        label={label}
        value={value}
        error={Boolean(error)}
        helperText={error || desc}
        onChange={(e) => handleChange(e.target.value)}
        fullWidth
      />
      break
    case 'text':
    case 'email':
      ele = <TextField
        type={type}
        name={name}
        variant={variant}
        required={required}
        label={label}
        value={value}
        error={Boolean(error)}
        helperText={error || desc}
        onChange={(e) => handleChange(e.target.value)}
        fullWidth
        InputProps={{
          ...inputProps
        }}
        multiline={multiline}
        rows={rows}
      />
      break;
    case 'mobile':
      ele = <TextField
        type="text"
        name={name}
        variant={variant}
        required={required}
        label={label}
        value={value}
        error={Boolean(error)}
        helperText={error || desc}
        InputProps={{
          startAdornment: <InputAdornment position="start">+91</InputAdornment>,
          ...inputProps
        }}
        inputProps={{ inputMode: 'numeric' }}
        onChange={(e) => handleChange(e.target.value)}
        fullWidth
      />
      break;
    case 'number':
      ele = <TextField
        type="text"
        name={name}
        size="small"
        variant={variant}
        required={required}
        label={label}
        value={value}
        defaultValue={defaultValue}
        error={Boolean(error)}
        helperText={error || desc}
        onChange={(e) => handleChange(e.target.value)}
        inputProps={{ inputMode: 'numeric' }}
        fullWidth
      />
      break;
    case 'amount':
      ele = <TextField
        type="text"
        size="small"
        name={name}
        variant={variant}
        required={required}
        label={label}
        value={value}
        defaultValue={defaultValue}
        error={Boolean(error)}
        helperText={error || desc}
        InputProps={{
          startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
        }}
        inputProps={{ inputMode: 'numeric' }}
        onChange={(e) => handleChange(e.target.value)}
        fullWidth
      />
      break;
    case 'dropdown':
      ele = <TextField
        select
        name={name}
        variant={variant}
        required={required}
        label={label}
        value={value[valueName] ? value[valueName] : ""}
        error={Boolean(error)}
        helperText={error || desc}
        fullWidth
      >
        {options.map((option) => (
          <MenuItem onClick={() => handleChange(option)} key={option[keyName]} value={option[valueName]}>
            {option[valueName]}
          </MenuItem>
        ))}
      </TextField>
      break;
    case 'autocomplete':
      ele = <Autocomplete
        onChange={(e,val) => handleChange(val)}
        isOptionEqualToValue={(option, value) => option[valueName] === value[valueName]}
        getOptionLabel={(option) => option[valueName]}
        options={options}
        filterOptions={(options, params) => {
          if(params.inputValue !== ""){
            const ops = options.filter((op)=>{
              return op[valueName]?.toLowerCase()?.includes(params.inputValue?.toLowerCase())
            })
            if(ops.length > 0){
              return ops
            }else{
              return [{
                [valueName]: params.inputValue
              }]
            }
          }else{
            return options
          }
        }}
        fullWidth
        renderInput={(params) => (
          <TextField
            error={Boolean(error)}
            {...params}
            required={required}
            label={label}
            helperText={error || desc}
            variant={variant}
            fullWidth
          />
        )}
      />
      break
    case 'datepicker':
      ele = <DatePicker
        name={name}
        variant={variant}
        required={required}
        label={label}
        value={value}
        error={Boolean(error)}
        helperText={error || desc}
        onChange={handleChange}
        min={min}
        max={max}
        fullWidth
      />
      break;
    case 'file':
      ele = (
        <Box
          border={1}
          borderColor={error ? 'red' : colorPallet.primary}
          borderRadius={5} align="center" p={2}>
          {
            fileUrls.length > 0 ?
              <div>
                {
                  fileUrls.map((furl, i) => {
                    return (
                      <Box position="relative"
                        display="inline-block"
                        p={2}
                        key={i}
                      >
                        <Box
                          position="absolute"
                          top="0"
                          right="0"
                        >
                          <CancelSharpIcon
                            color="primary"
                            onClick={() => { handleRemoveFile(i) }}
                          />
                        </Box>
                        <object
                          type={furl.extension}
                          data={furl.url}
                          width="50"
                          height="50"
                        >
                        </object>
                      </Box>
                    )
                  })
                }
              </div>
              :
              <>
                <input
                  accept={accept}
                  id="contained-button-file"
                  multiple={multiple}
                  type="file"
                  hidden
                  onChange={handleFileChange}
                />
                <label style={{ marginBottom: 0 }} htmlFor="contained-button-file">
                  <Button
                    variant={variant}
                    component="span"
                    startIcon={<CloudUploadIcon />}
                  >
                    {label}
                  </Button>
                </label>
              </>
          }
        </Box>
      )
      break;
    default:
      ele = null
  }

  return ele
}