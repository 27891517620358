import styled from 'styled-components'
import { colorPallet } from './colors'

export const Row = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  width: ${props => props.width || 'auto'};
  align-items: ${props => props.align || 'start'};
  justify-content: ${props => props.justify || 'start'};
  background-color: ${props => props.bgColor || 'unset'};
  flex-wrap: ${props=> props.flexWrap || 'wrap'};
  flex-grow: ${props => props.flexGrow || 'initial'};
  column-gap: ${props=>props.columnGap || 'initial'};
  height: ${props=>props.height || 'initial'};
`

export const Box = styled.div`
  text-align: ${props=>props.textAlign || 'left'};
  display: ${props=>props.display || 'block'};
  width: ${props => props.w || 'auto'};
  margin: ${props=>props.margin || '0 auto'};
  @media (min-width: 576px){
    width: ${props=>props.sm || props.w || 'auto'};
  }

  @media (min-width: 768px){
    width: ${props=>props.md || props.sm || props.w ||  'auto'};
  }

  @media (min-width: 992px){
    width: ${props=> props.lg || props.md || props.sm || props.w ||  'auto'};
  }

  @media (min-width: 1200px){
    width: ${props=> props.xl || props.lg || props.md || props.sm || props.w || 'auto'};
  }
`

export const Divider = styled.div`
  border-bottom: 1px solid ${colorPallet.borderGrey};
  margin-top: ${props=>props.top || '10px'};
  margin-bottom: ${props=>props.bottom || '10px'};
  width: ${props=>props.width || '100%'};
`