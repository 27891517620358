export const loginApi = `${process.env.REACT_APP_HOSTNAME}/login`
export const deleteAccountRequestApi = `${process.env.REACT_APP_HOSTNAME}/delete-account-request`
export const signupApi = `${process.env.REACT_APP_HOSTNAME}/signup`
export const getCategoriesApi = `${process.env.REACT_APP_HOSTNAME}/get-categories`
export const getBrandsApi = `${process.env.REACT_APP_HOSTNAME}/get-brands`
export const getSupplierProductDetailApi = (productId)=>`${process.env.REACT_APP_HOSTNAME}/get-supplier-product-detail/${productId}`
export const getOrderedProductDetailApi = (orderProductId)=>`${process.env.REACT_APP_HOSTNAME}/get-ordered-product-detail/${orderProductId}`
export const updateSupplierVariantApi = (supplierVariantId)=>`${process.env.REACT_APP_HOSTNAME}/update-supplier-variant/${supplierVariantId}`
export const updateInventoryJobApi = (jobId)=>`${process.env.REACT_APP_HOSTNAME}/update-inventory-job/${jobId}`
export const updateSupplierProductApi = (supplierProductId)=>`${process.env.REACT_APP_HOSTNAME}/update-supplier-product/${supplierProductId}`
export const getProductsApi = `${process.env.REACT_APP_HOSTNAME}/get-products`
export const getLastInventoryJobApi = `${process.env.REACT_APP_HOSTNAME}/get-last-inventory-job`
export const getSupplierProductsApi = `${process.env.REACT_APP_HOSTNAME}/get-supplier-products`
export const validateApi = `${process.env.REACT_APP_HOSTNAME}/validate-otp`
export const getUserProfileApi = `${process.env.REACT_APP_HOSTNAME}/get-user-profile`
export const getWhitelabelApi = `${process.env.REACT_APP_HOSTNAME}/get-whitelabel-data`
export const placeOrderApi = `${process.env.REACT_APP_HOSTNAME}/place-order`
export const addAddressApi = `${process.env.REACT_APP_HOSTNAME}/add-address`
export const getAddressesApi = `${process.env.REACT_APP_HOSTNAME}/get-addresses`
export const getDeliveryDetailApi = `${process.env.REACT_APP_HOSTNAME}/get-delivery-cost`
export const validatePaymentApi = `${process.env.REACT_APP_HOSTNAME}/validate-payment`
export const verifySessionApi = `${process.env.REACT_APP_HOSTNAME}/verify-session`
export const logoutApi= `${process.env.REACT_APP_HOSTNAME}/logout`
export const getProductRequestDetailApi = (productReqId)=>`${process.env.REACT_APP_HOSTNAME}/get-product-req-detail/${productReqId}`
export const getTotalSaleAmount = `${process.env.REACT_APP_HOSTNAME}/get-total-sale-amount`
export const getOrdersApi = `${process.env.REACT_APP_HOSTNAME}/get-ordered-products`
export const updateInventoryApi = `${process.env.REACT_APP_HOSTNAME}/update-inventory`
export const upsertNewProductRequestApi = `${process.env.REACT_APP_HOSTNAME}/upsert-new-product-request`
export const upsertNewVariantRequestApi = `${process.env.REACT_APP_HOSTNAME}/upsert-new-variant-request`
export const getDataUploadUrlApi = `${process.env.REACT_APP_HOSTNAME}/get-data-upload-url`
export const getProductReqListApi = `${process.env.REACT_APP_HOSTNAME}/get-product-req-list`