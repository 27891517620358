import { CircularProgress } from "@mui/material"
import { isArray } from "lodash"
import { CustomTextStyle } from "./index.style"

function CustomText(props) {
  const {
    children, className = "", isRupee = false, onClick, overflowLength = 0, showLoader = false, loaderColor="white"
  } = props

  let contentStr = ''
  if(overflowLength){
    contentStr = isArray(children)? children.join("") : children
  }

  return (
    <CustomTextStyle {...props} className={className} onClick={onClick ? onClick : undefined} >
      {
        showLoader ?
          <>
            <CircularProgress
              color={loaderColor}
             />
          </>
          :
          <>
            {
              isRupee &&
              <span>
                ₹
              </span>
            }
            {
              overflowLength && (contentStr?.length > overflowLength) ?
                <>
                  {
                    contentStr.substr(0, overflowLength)
                  }
                  ...
                </>
                :
                children
            }
          </>
      }
    </CustomTextStyle>
  )
}

export default CustomText
