import { Avatar, Button, Card, CardActionArea, CardContent, Checkbox, Modal } from '@mui/material'
import { isDate, isEmpty } from 'lodash'
import { useCallback, useState } from 'react'
import { useTheme } from 'styled-components'
import { Row } from '../../../../commonStyles/index.style'
import CustomText from '../../../../components/CustomText'
import SupplierVariantForm from '../../../../components/SupplierVariantForm'
import { VariantModalStyle, VariantsListStyle } from './index.style'

function VariantsList(props) {
  const { variants = [], onComplete, product } = props
  const theme = useTheme()
  const [isShowModal, setIsShowModal] = useState(false)
  const [selectedVariant, setSelectedVariant] = useState({})

  const [selectedVariantsList, setSelectedVariantsList] = useState({})

  const handleCheckboxClick = useCallback((v) => {
    const vData = {
      productName: product.productName,
      productId: product.productId,
      ...v.supplierVariantData,
      variantName: v.variantName,
      variantId: v.variantId
    }
    const newSelectedVariantsList = {...selectedVariantsList}
    if (v.variantId in newSelectedVariantsList) {
      delete newSelectedVariantsList[v.variantId]
      setSelectedVariantsList({ 
        ...newSelectedVariantsList, 
      })
    }else{
      setSelectedVariant({...vData})
      handleToggleModal()
    }
  }, [selectedVariantsList, product])

  const handleToggleModal = useCallback(() => {
    setIsShowModal((m) => !m)
  }, [])

  const handleAddVariant = useCallback((status, data) => {
    if (status === 'success') {
      setSelectedVariantsList({
        ...selectedVariantsList,
        [data.variantId]: {
          ...data
        }
      })
    }
    handleToggleModal()
  }, [selectedVariantsList])

  const handleCompleteClick = useCallback(() => {
    onComplete(selectedVariantsList)
  }, [onComplete,selectedVariantsList])

  const handleCheckVariantStatus = (v) => {
    // if variantId is not in selectedVariantsList and v.supplierVariantData is NOT empty -> checked
    // if variantId is not in selectedVariantsList and v.supplierVariantData is empty -> unchecked
    // if variantId is in selectedVariantsList and value selectedVariantsList[variantId].isSelected is not false -> checked
    // if variantId is in selectedVariantsList but value selectedVariantsList[variantId].isSelected is false -> unchecked

    if (v.variantId in selectedVariantsList) {
      return selectedVariantsList[v.variantId]
    } else {
      return !isEmpty(v.supplierVariantData)
    }
  }

  const isAlreadyAdded = (v)=>{
    return !isEmpty(v.supplierVariantData)
  }

  return (
    <VariantsListStyle>
      <Row justify="center" columnGap="15px" className="item-cards-container">
        {
          variants?.map((v) => {
            return (
              <Card sx={{ borderRadius: '10px', flexShrink: 0, width: '45%', height: '260px' }} key={v.variantId} className="item-card">
                <CardActionArea>
                  <Avatar
                    alt={v.variantName}
                    src={v.imgUrl?.[0] ? v.imgUrl?.[0] : {}}
                    sx={{ bgcolor: theme.color.blue.main, height: 160, width: '100%' }}
                    variant="rounded"
                  />
                  <CardContent sx={{ padding: '5px', height: '100px' }}>
                    <Row flexDirection="column" justify="space-between" height="100%" align="stretch">
                      <CustomText
                        overflowLength={20}
                        fontColor={theme.color.dark}
                        fontSize="m1"
                        textAlign="center"
                      >
                        {v?.variantName}
                      </CustomText>
                      <Checkbox
                        disabled={isAlreadyAdded(v)}
                        checked={handleCheckVariantStatus(v)}
                        onChange={() => handleCheckboxClick(v)}
                      />
                    </Row>
                  </CardContent>
                </CardActionArea>
              </Card>
            )
          })
        }
      </Row>
      {
        !isEmpty(selectedVariantsList) && 
        <div className='section-add-btn'>
          <Button fullWidth sx={{ borderRadius: 12.5, padding: "15px" }} size="large" variant="contained" onClick={handleCompleteClick}>
            Complete
          </Button>
        </div>
      }
      <Modal
        open={isShowModal}
        onClose={handleToggleModal}
        aria-labelledby="Variant Detail"
      >
        <VariantModalStyle>
          <SupplierVariantForm
            defaultData={selectedVariant}
            variantId={selectedVariant.variantId}
            handleCallback={handleAddVariant}
            useCase='ADD'
          />
        </VariantModalStyle>
      </Modal>
    </VariantsListStyle>
  )
}

export default VariantsList