import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from 'react';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useCallback } from 'react';

export default function Searchbar(props) {
  const [open, setOpen] = useState(false)

  const { options = [], onInputChange, onChange, loading, optionLabelKey='label' } = props

  const handleInputChange = useCallback((e, val) => {
    if(e.type === 'change'){
      onInputChange(val)
    }
  }, [onInputChange])

  const handleChange = useCallback((e,val) => {
    onChange(val)
  }, [onChange])

  return (
    <Autocomplete
      id="async-search"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.productName === value.productName}
      onInputChange={handleInputChange}
      onChange={handleChange}
      getOptionLabel={(option) => option[optionLabelKey]}
      options={options}
      loading={loading}
      filterOptions={(options, params) => {
        if(params.inputValue !=="" && options.length === 0){
            return [{
              productName: `Add "${params.inputValue}"`
            }]
        }
        return options
      }}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
              </>
            ),
          }}
        />
      )}
    />
  );
}