import styled from "styled-components"

export const OrdersStyle = styled.div`
  padding: 0px 10px 0px;
  margin-bottom: 10vh;
  .filters{
    margin-top: 10px;
  }
  .items-list{
    padding-top: 10px;
  }
`