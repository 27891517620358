import LoginContext from './context';
import { useState, useCallback, useEffect } from 'react';
import { verifySessionApi } from '../../config/urls';
import ShowSnackbar from '../../components/ShowSnackbar';
import { getAuthHeaders, getUserProfileUtil, parseError } from '../../helpers/utility';
import Cookie from 'js-cookie'

/*
  1. call verify-session API here - don't render anything until the response of API comes
  2. render children when the response comes for both failed/successfull response
  3. In Router create a HOC-RestrictedRoute which sees if isLoggedIn is true/false and if it's false redirect to route /login 
  4. Don't wrap login route with RestrictedRoute,On Logging In when the response of login API comes check if it's 
  successfull redirect to home page else show snackbar
  5. The children of LoginProvider is RoutesCOntainer which hides the default initial loader when it renders
*/

function LoginProvider(props) {
  const [isLoggedIn, setIsLoggedIn] = useState(undefined)

  useEffect(() => {
    const verifySession = async ()=>{
      const response = await fetch(verifySessionApi,{
        headers: getAuthHeaders(),
        method: 'GET'
      })
      if(response.ok){
        getUserProfileUtil()
        setIsLoggedIn(true)
      }else{
        //if token is expired, remove cookies
        Cookie.remove('authToken')
        Cookie.remove('userProfile')
        setIsLoggedIn(false)
      }
    }
    if(Cookie.get('authToken')){
      verifySession()
    }else{
      setIsLoggedIn(false)
    }
  }, [])

  const handleUpdateLoginState = useCallback((state) => {
    setIsLoggedIn(state)
  }, [])

  //showing loader till the login value is not updated after the response of verifySession is arrived
  if(isLoggedIn !== undefined){
    return (
      <LoginContext.Provider
        value={{
          handleUpdateLoginState,
          isLoggedIn
        }}
      >
        {props.children}
      </LoginContext.Provider>
    )
  }
  
}

export default LoginProvider