import Cookie from 'js-cookie'
import { colorPallet } from '../commonStyles/colors'
import ShowSnackbar from '../components/ShowSnackbar'
import { getDataUploadUrlApi, getUserProfileApi } from '../config/urls'

export const parseError = (err)=>{
  let error = ''
  switch(typeof err){
    case 'object':
      Object.keys(err).forEach((errorItem,i)=>{
        if(i !== 0){
          error = `${error} & ${err[errorItem]}`
        }else{
          error = `${err[errorItem]}`
        }
      })
      break
    case 'string':
      error = err
      break
    default:
      error = 'Some error Occured'
  }
  return error
}

export const getHeaders = ()=>{
  return {
    'Content-Type':'application/json'
  }
}

export const getAuthHeaders = ()=>{
  return {
    'Content-Type':'application/json',
    'authorization': `Bearer ${Cookie.get('authToken')}`
  }
}

export const queryBuilder = (finalFilter) => {
  const queryArray = []
  Object.keys(finalFilter).forEach((key) => {
    const keyValue = finalFilter[key]
    let query = ''
    const identifier = queryArray.length ? '&' : '?'
    if (Array.isArray(keyValue)) {
      keyValue.forEach((value) => {
        query += `${query ? '&' : ''}${key}=${value}`
      })
    } else if (keyValue) {
      query += `${key}=${keyValue}`
    }
    if (query) {
      queryArray.push(`${identifier}${query}`)
    }
  })
  return queryArray.join('')
}

export const formatMoney = (amount, currency='Rs')=>{
  return `${currency} ${amount}`
}

export const isMobileScreen = () => {
  const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  return (screenWidth <= 768)
}

const getUserProfile = async ()=>{
  const response = await fetch(getUserProfileApi,{
    headers: getAuthHeaders(),
    method: 'GET'
  })
  if(response.ok){
    const json = await response.json()
    return {
      success: true,
      data: json?.data
    }
  }else{
    const errorJson = await response.json()
    return {
      success: false,
      error: errorJson
    }
  }
}

export const getUserProfileUtil = async (callDirectly=false) => {
  // if(callDirectly){
    const userProfileData = await getUserProfile()
    if(userProfileData.success){
      //Profile call will go once in a day
      const data = JSON.stringify(userProfileData.data)
      Cookie.set('userProfile', data,{ expires: 1 })
      return userProfileData.data
    }else{
      ShowSnackbar.error({
        text: parseError(userProfileData.error),
        visible: true
      })
    }
  // }else{
  //  if(Cookie.get('userProfile')){
  //   const userProfileData = JSON.parse(Cookie.get('userProfile'))
  //   return userProfileData
  //  }else{
  //   return getUserProfileUtil(true)
  //  }
  // }
}

export const getAwsSignedUrl = async ()=>{
  const response = await fetch(getDataUploadUrlApi,{
    headers: getAuthHeaders(),
    method: 'GET'
  })
  if(response.ok){
    const json = await response.json()
    return {
      success: true,
      data: json?.data
    }
  }else{
    const errorJson = await response.json()
    return {
      success: false,
      error: errorJson
    }
  }
}

export const uploadImagesToAwsSigned = async (imgs, successCallback, errorCallback)=>{
  const IMAGE_HOST_NAME = 'https://gowinkl.s3.ap-south-1.amazonaws.com'
  //creating an array and pushing image to it in case of single image
  const images = Array.isArray(imgs) ? imgs: [imgs]
  const signedUrlsData = await getAwsSignedUrl()
  let uploadPercent = 0
  let failedImages = []
  const uploadedFilePaths = []
  //each element of array contributes 'percentGrainPerUpload' to total uploadPercent
  const percentGrainPerUpload = Math.ceil(100/images.length)
  if(signedUrlsData.success){
    images.forEach((file,i)=>{
      const formdata = new FormData()
      Object.entries(signedUrlsData.data.fields).forEach(([field, value]) => {
        formdata.append(field, value)
      })
      formdata.append('file', file)
      //add extension logic here below
      const fileExtension = file.name.split(".")[file.name.split(".").length-1]
      const fileName = `product/images/${Date.now()}.${fileExtension}`
      formdata.set('key',fileName)
      
      fetch(signedUrlsData.data.url,{
        method: 'POST',
        body: formdata
      })
      .then((response)=>{
        if(response.ok){
          uploadPercent = uploadPercent+percentGrainPerUpload
          if(uploadPercent >= 100){
            uploadPercent = 100
          }
          uploadedFilePaths.push(`${IMAGE_HOST_NAME}/${fileName}`)
          successCallback(uploadPercent,uploadedFilePaths)
        }else{
          throw new Error()
        }
      })
      .catch((err)=>{
        failedImages.push(file)
        if(i === images.length-1){
          errorCallback({
            type: 'partial',
            failedImages
          })
        }
      })
    })
  }else{
    errorCallback({
     type: 'complete' 
    })
  }
}

export const getCartId = ({productId,variantId})=>{
  return `${productId}-${variantId}`
}

export const getProductId= (cartId)=>{
  return cartId.split("-")[0]
}

export const getVariantImages = (variantId,productDetail)=>{
  for(let i=0 ; i < productDetail?.variant?.length; i++){
    const vd = productDetail.variant[i]
    if(vd.variantId === variantId){
      if(vd?.imgUrl?.length > 0){
        return vd.imgUrl
      }else{
        return productDetail.imgUrl
      }
    }
  }
}

export const getScreen = ()=>{
  if(window.matchMedia( '( min-width: 576px )' ).matches){
    return 'sm'
  }else if(window.matchMedia( '( min-width: 768px )' ).matches){
    return 'md'
  }else if(window.matchMedia( '( min-width: 992px )' ).matches){
    return 'lg'
  }else if(window.matchMedia( '( min-width: 1200px )' ).matches){
    return 'xl'
  }else{
    return 'w'
  }
}

export const PRODUCT_REQ_STATUS = {
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  REJECTED: 'REJECTED',
  COMPLETED: 'COMPLETED'
}

export const VARIANT_REQ_STATUS = {
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  REJECTED: 'REJECTED',
  COMPLETED: 'COMPLETED'
}

export const PROCESSED_ORDER_PRODUCT_STATUS = {
  'APPROVAL_PENDING': 'APPROVAL_PENDING',
  'ACTIVE': 'ACTIVE',
  'COMPLETED': 'COMPLETED',
  'CANCELLED': 'CANCELLED',
  'RETURNED': 'RETURNED',
}

export const ORDER_STATUS = {
  APPROVAL_PENDING: 'APPROVAL_PENDING',
  ACTIVE: 'ACTIVE',
  CANCELLED: 'CANCELLED',
  COMPLETED: 'COMPLETED'
}

export const PRODUCT_STATUS = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED'
}

export const VARIANT_STATUS = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED'
}

export const getVariantStatus = (status)=>{
  switch(status){
    case VARIANT_STATUS.ACTIVE:
      return {
        color: colorPallet.green.main,
        label: 'ACTIVE',
        key: status
      }
    case VARIANT_STATUS.DISABLED:
      return {
        color: colorPallet.red.main,
        label: 'DISABLED',
        key: status
      }
  }
  return undefined
}

export const getProductStatus = (status)=>{
  switch(status){
    case PRODUCT_STATUS.ACTIVE:
      return {
        color: colorPallet.green.main,
        label: 'ACTIVE',
        key: status
      }
    case PRODUCT_STATUS.DISABLED:
      return {
        color: colorPallet.red.main,
        label: 'DISABLED',
        key: status
      }
  }
}

export const getOrderStatus = (processedOrderProductStatus) => {
  switch (processedOrderProductStatus) {
      case PROCESSED_ORDER_PRODUCT_STATUS.APPROVAL_PENDING:
          return {
              color: colorPallet.yellow.main,
              label: 'Approval Pending'
          }
      case PROCESSED_ORDER_PRODUCT_STATUS.ACTIVE:
          return {
              color: colorPallet.green.main,
              label: 'ACTIVE'
          }
      case PROCESSED_ORDER_PRODUCT_STATUS.COMPLETED:
          return {
              color: colorPallet.green.main,
              label: 'COMPLETED'
          }
      case PROCESSED_ORDER_PRODUCT_STATUS.CANCELLED:
          return {
              color: colorPallet.red.main,
              label: 'CANCELLED'
          }
      case PROCESSED_ORDER_PRODUCT_STATUS.RETURNED:
          return {
              color: colorPallet.orange,
              label: 'RETURNED'
          }
  }
}

export const getProductReqStatus = (status)=>{
  switch(status){
    case PRODUCT_REQ_STATUS.PENDING:
      return {
        color: colorPallet.yellow.main,
        label: 'PENDING',
        key: status
      }
    case PRODUCT_REQ_STATUS.ACTIVE:
      return {
        color: colorPallet.green.main,
        label: 'ACTIVE',
        key: status
      }
    case PRODUCT_REQ_STATUS.REJECTED:
      return {
        color: colorPallet.red.main,
        label: 'REJECTED',
        key: status
      }
    case PRODUCT_REQ_STATUS.COMPLETED:
      return {
        color: colorPallet.green.main,
        label: 'COMPLETED',
        key: status
      }
  }
}

export const getVariantReqStatus = (status)=>{
  switch(status){
    case VARIANT_REQ_STATUS.PENDING:
      return {
        color: colorPallet.yellow.main,
        label: 'PENDING',
        key: status
      }
    case VARIANT_REQ_STATUS.ACTIVE:
      return {
        color: colorPallet.green.main,
        label: 'ACTIVE',
        key: status
      }
    case VARIANT_REQ_STATUS.REJECTED:
      return {
        color: colorPallet.red.main,
        label: 'REJECTED',
        key: status
      }
    case VARIANT_REQ_STATUS.COMPLETED:
      return {
        color: colorPallet.green.main,
        label: 'COMPLETED',
        key: status
      }
  }
}

export const getStockColor = (count)=>{
  if(Number(count) === 0){
    return colorPallet.red.main
  }else if(Number(count) < 5){
    return colorPallet.yellow.main
  }else{
    return colorPallet.green.main
  }
}

export const getDiscountPercent = (MRP, SP)=>{
  if(MRP != 0 && SP != 0){
    return (((MRP-SP)/MRP) *100).toFixed(2)
  }else{
    return 0
  }
}


export const stringAvatar = (name) => {
  if(name){
    let nameArr = name.split(" ")
    let finalVal = ''
    nameArr.forEach((n)=>{
      finalVal = `${finalVal}${n.charAt(0)}`
    })
    return finalVal
  }else{
    return ''
  }
}